import React, { useEffect } from "react";
import { connect } from "react-redux";

import Participant from "../Participant/Participant";
import { isMobileScreenSize, roundNumber } from "../../../../utils/helpers";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useNetworkQuality } from "../../hooks/useNetworkQuality/useNetworkQuality";
import { useActiveUsers } from "../../hooks/useActiveUsers/useActiveUsers";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useMainParticipantId from "../../hooks/useMainParticipantId/useMainParticipantId";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import store from "store";
import { partnerConnectedAction, setIsVisitorAloneAction, setVideoConnectedAction } from "store/room/actions";
import { showFishbowlPopupAction } from "store/controller/actions";
import { saveUserAction } from "store/users/actions";


const ParticipantList = ({
                             currentUser, participants, activeSharingId, isStartedSelfShare,
                             partnerConnectedAction, setIsVisitorAloneAction,
                             showFishbowlPopupAction, setVideoConnectedAction, zmClient,
                             saveUserAction
                         }) => {
    const { isStartShareScreenWithVideoElement, rerenderParticipant, isStartedLocalVideo } = useVideoContext();
    // Subscribe:
    useParticipants(zmClient);
    const isMobileScreen = isMobileScreenSize();
    const networkQuality = useNetworkQuality(zmClient);
    const activeUsers = useActiveUsers(zmClient);
    const { horizontablePosition } = useVideoContext();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const mainParticipantId = useMainParticipantId({ participants, selectedParticipant, currentUser });

    const existParticipants = participants.filter(({ userIdentity, isVideoConnect }) => userIdentity && isVideoConnect);
    const localParticipant = existParticipants.find(({userIdentity})=> userIdentity === currentUser.userId);
    const participantsLength = existParticipants.length;
    const mainParticipantIndex = participants.findIndex(({ userId }) => userId === mainParticipantId);
    const emptyParticipantIndex = participants.findIndex(({ userIdentity }) => !userIdentity);

    useEffect(()=>{
        console.log('getSessionInfo', zmClient.getSessionInfo());
        saveUserAction({
            view: 'ParticipantList',
            type: 'zoom-room',
            label: 'zoom',
            description: JSON.stringify(zmClient.getSessionInfo())
        });
    }, [])

    useEffect(()=>{
        if (mainParticipantId) {
            setTimeout(()=>{
                rerenderParticipant(null, mainParticipantId);
            }, 500);
        }
    }, [mainParticipantId])

    useEffect(() => {
        if (participantsLength > 1) {
            partnerConnectedAction(true);
            setIsVisitorAloneAction(false);
            showFishbowlPopupAction(false);
            const { videoConnected } = store.getState().controller.currentSession;

            if (!videoConnected) setVideoConnectedAction(true);
        }
    }, [participantsLength]);

    const renderParticipant = ({
                                   key, style, isStartShareScreenWithVideoElement,
                                   isScreenSharing, isLocalParticipant, networkQualityLevel,
                                   participant, isCurrentUserSpeaking, isMainParticipant,
                                   onClick, isLoading
    }) => (
        <div key={key} style={style}>
            <Participant {...{
                isStartShareScreenWithVideoElement,
                isStartedSelfShare,
                isScreenSharing,
                isLocalParticipant,
                networkQualityLevel,
                participant,
                isMainParticipant,
                isCurrentUserSpeaking,
                onClick,
                isLoading
            }} />
        </div>
    );

    if (isMobileScreen) {
        return (
            <>
                {renderParticipant({
                    key: `local-participant`,
                    style: {
                        zIndex: 1,
                        display: participantsLength > 1 ? "none" : "block",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "100%",
                        height: "100%"
                    },
                    isStartShareScreenWithVideoElement: isStartShareScreenWithVideoElement,
                    isScreenSharing: activeSharingId === localParticipant?.userId,
                    isLocalParticipant: true,
                    networkQualityLevel: roundNumber(Math.min(networkQuality[localParticipant?.userId]?.downlink ?? 5, networkQuality[localParticipant?.userId]?.uplink ?? 5), 0),
                    participant: localParticipant,
                    isLoading: !isStartedLocalVideo,
                    isCurrentUserSpeaking: activeUsers.some((item) => item.userId === localParticipant?.userId)
                })}
                {participants.filter(({ userIdentity }) => userIdentity !== currentUser.userId)
                                .map((user, index) => {
                                    const position = (emptyParticipantIndex !== -1 && emptyParticipantIndex < index) ? index - 1 : index;
                                    const mobileSizePercentage = 100 / (participantsLength - 1) - (horizontablePosition ? 0 : 10);
                                    const mobileTop = horizontablePosition || participantsLength === 2 ? 0 : position * mobileSizePercentage;
                                    const mobileRight = !horizontablePosition || participantsLength === 2 ? 0 : position * mobileSizePercentage;
                                    const userId = user?.userId;
                                    const networkQualityData = networkQuality[userId];
                                    const isExistParticipant = user && user.userIdentity;

                                    return renderParticipant({
                                        key: `user-${index}`,
                                        style: {
                                            zIndex: index + 2,
                                            display: isExistParticipant ? "block" : "none",
                                            position: "absolute",
                                            top: isMobileScreen ? `${mobileTop}%` : (position === 0 ? 15 : (position * 200 + (position + 1) * 15)),
                                            right: isMobileScreen ? `${mobileRight}%` : 15,
                                            width: horizontablePosition && isMobileScreen ? `${mobileSizePercentage}%` : isMobileScreen ? "100%" : 356,
                                            height: !horizontablePosition && isMobileScreen ? `${mobileSizePercentage}%` : isMobileScreen ? "100%" : 200
                                        },
                                        isStartShareScreenWithVideoElement: isStartShareScreenWithVideoElement,
                                        isScreenSharing: activeSharingId === userId,
                                        isLocalParticipant: false,
                                        networkQualityLevel: roundNumber(Math.min(networkQualityData?.downlink ?? 5, networkQualityData?.uplink ?? 5), 0),
                                        participant: user,
                                        isCurrentUserSpeaking: activeUsers.some((item) => item.userId === userId),
                                        isLoading: user?.audio !== 'computer'
                                    })
                                })
                }
            </>
        )
    }

    return (
        <>
            {participants.map((user, index) => {
                const isLocalParticipant = currentUser.userId === user?.userIdentity;
                const positionAmendment = (emptyParticipantIndex !== -1 && emptyParticipantIndex < index) ? index - 1 : index;
                const position = (mainParticipantIndex !== -1 && mainParticipantIndex < index) ? positionAmendment - 1 : positionAmendment;
                const isExistParticipant = user && user.userIdentity;
                const userId = user?.userId;
                const networkQualityData = networkQuality[userId];
                const isMainParticipant = userId === mainParticipantId;

                return renderParticipant({
                    key: `user-${index}`,
                    style: {
                        zIndex: isMainParticipant ? 1 : index + 2,
                        display: isExistParticipant ? "block" : "none",
                        position: "absolute",
                        top: isMainParticipant ? 0 : position === 0 ? 15 : position * 200 + ((position + 1) * 15),
                        right: isMainParticipant ? 0 : 15,
                        width: isMainParticipant ? "100%" : 356,
                        height: isMainParticipant ? "100%" : 200,
                        transition: "top .3s, right .3s, width .3s, height .3s"
                    },
                    isStartShareScreenWithVideoElement: isStartShareScreenWithVideoElement,
                    isStartedSelfShare: isLocalParticipant && isStartedSelfShare,
                    isScreenSharing: activeSharingId === userId,
                    isLocalParticipant: isLocalParticipant,
                    networkQualityLevel: roundNumber(Math.min(networkQualityData?.downlink ?? 5, networkQualityData?.uplink ?? 5), 0),
                    participant: user,
                    isMainParticipant: isMainParticipant,
                    onClick: isExistParticipant && !isMobileScreenSize() && !isMainParticipant ? () => setSelectedParticipant(user) : null,
                    isCurrentUserSpeaking: activeUsers.some((item) => item.userId === userId),
                    isLoading: isLocalParticipant ? !isStartedLocalVideo : user?.audio !== 'computer'
                });
            })}
        </>
    );
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
    currentUser: store.users.currentUser,
    participants: store.zoom.participants,
    videoConnected: store.controller.currentSession.videoConnected
});

const mapDispatchToProps = {
    partnerConnectedAction, setIsVisitorAloneAction,
    showFishbowlPopupAction, setVideoConnectedAction,
    saveUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList);
