import { useEffect, useRef } from "react";
import store from "../../../../store";
import { memberLeaveConferenceAction, partnerConnectedAction } from "../../../../store/room/actions";
import { setErrorInfoBlockVisibleAction, switchFishbowlViewAction } from "../../../../store/controller/actions";
import {
    addParticipant,
    disconnectParticipant,
    initParticipants,
    updateParticipant
} from "../../../../store/zoom/actions";
import fishbowlTypes from "../../../Popups/FishbowlViewTypes";

const maxParticipants = 3;

export default function useParticipants(zmClient) {
  const timeout = useRef(null);

  useEffect(() => {
    if (zmClient) {
      const allParticipants = zmClient?.getAllUser()?.filter(({userIdentity, isInFailover}) => userIdentity && !userIdentity.includes('-mux-') && !isInFailover);
      const allUniqueParticipants = allParticipants.filter((value, index, self) => {
        return self.findIndex(obj => obj.userIdentity === value.userIdentity) === index;
      });

      const emptyParticipants = Array.from(
          { length: maxParticipants },
          () => ({})
      );

      // Related with Zoom issue "Too many active WebGL contexts"
      store.dispatch(initParticipants(
          emptyParticipants.map((item, index) => (
              allUniqueParticipants[index] || item
          ))
      ));

      if (zmClient) {
        zmClient.on('user-added', participantConnected);
        zmClient.on('user-removed', participantDisconnected);
        zmClient.on('user-updated', updateUser);
      }

      return () => {
        if (timeout && timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = null;
        }
        if (zmClient) {
          zmClient.off('user-added', participantConnected);
          zmClient.off('user-removed', participantDisconnected);
          zmClient.off('user-updated', updateUser);
        }
      };
    }
  }, [zmClient]);

  const participantConnected = (data) => {
    const participantUserId = data[0]?.userId;

    const participant = (zmClient?.getAllUser() || []).find(({userId})=> userId === participantUserId);
    if (!participant || !participant.userIdentity || participant.isInFailover || participant.userIdentity?.includes('-mux-')) return;

    const {
      controller: { errorInfoBlockReasons, errorInfoBlock },
      zoom: {participants}
    } = store.getState();

    if (timeout && timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    const isExist = participants.some(({userIdentity})=> userIdentity === participant?.userIdentity);
    if (isExist) return;

    if (errorInfoBlock) {
      store.dispatch(setErrorInfoBlockVisibleAction(false, {
        ...errorInfoBlockReasons, reconnectingWarning: false
      }));
    }

    store.dispatch(addParticipant(participant));
  };

  const participantDisconnected = (data) => {
    const participant = data[0];
    store.dispatch(memberLeaveConferenceAction());
    store.dispatch(disconnectParticipant(participant?.userIdentity));

    const numberUsers = parseInt(store.getState().controller.currentSession.numberUsers);
    const participantsLength = (zmClient?.getAllUser() || []).filter(({userIdentity, isInFailover}) => !isInFailover && !userIdentity.includes('-mux-')).length;
    const isThreeWayCall = !!store.getState().users.otherUserB.userId;

    if (numberUsers === 2 || (isThreeWayCall && participantsLength === 1)) {
      store.dispatch(partnerConnectedAction(false));
    }

    if ((!timeout || !timeout.current) && (!isThreeWayCall || (isThreeWayCall && participantsLength === 1))) {
      timeout.current = setTimeout(() => {
        const fishbowlView = store.getState().controller.fishbowlView;
        const currentParticipantsLength = (zmClient?.getAllUser() || []).filter(({userIdentity, isInFailover}) => !isInFailover && !userIdentity.includes('-mux-')).length;

        if (currentParticipantsLength === 1 && fishbowlView !== fishbowlTypes.VIDEO_DISCONNECTED_I_USER && fishbowlView !== fishbowlTypes.VIDEO_DISCONNECTED_O_USER) {
          store.dispatch(switchFishbowlViewAction(fishbowlTypes.VIDEO_DESTROYED));
        }

        if (timeout && timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = null;
        }
      }, 20 * 1000);
    }
  };

  const updateUser = (data) => {
    const participantUserId = data[0]?.userId;
    const participant = (zmClient?.getAllUser() || []).find(({userId})=> userId === participantUserId);

    if (!participant || !participant.userIdentity || participant.userIdentity?.includes('-mux-')) return;

    if (participant.isInFailover) {
      return participantDisconnected(data);
    }

    const { zoom: {participants} } = store.getState();
    const existParticipant = participants.find(({userIdentity})=> userIdentity === participant?.userIdentity);
    if (!existParticipant && participant.isVideoConnect) {
      return participantConnected(data);
    }

    const isEqual = JSON.stringify(existParticipant) === JSON.stringify(participant)
    if (participant.isVideoConnect && !isEqual) {
      store.dispatch(updateParticipant(participant));
    }
  };
}
