import { useCallback, useEffect, useState } from "react";
import { SHARE_CANVAS_ID } from "../../utils/videoConstants";

export function useShare(zmClient, mediaStream) {
    const [isStartedSelfShare, setIsStartedSelfShare] = useState(false);
    const [activeSharingId, setActiveSharingId] = useState(0);

    const onActiveShareChange = useCallback(({ state, userId }) => {
        if (state === 'Inactive') {
            mediaStream.stopShareView();
            setActiveSharingId(null);
        } else if (state === 'Active') {
            setActiveSharingId(userId);
            const targetElement = document.getElementById(`${SHARE_CANVAS_ID}_${userId}`);
            if (mediaStream && targetElement) mediaStream.startShareView(targetElement, userId);
        }
    }, []);

    const onCurrentUserUpdate = useCallback((payload) => {
        if (Array.isArray(payload) && payload.length > 0) {
            payload.forEach((item) => {
                if (item.userId === zmClient.getSessionInfo().userId && item.sharerOn !== undefined) {
                    setIsStartedSelfShare(item.sharerOn);
                }
            });
        }
    }, [zmClient]);

    const onShareContentChange = useCallback((payload) => {
        setActiveSharingId(payload.userId);
    }, []);

    useEffect(() => {
        if (zmClient) {
            zmClient.on('active-share-change', onActiveShareChange);
            zmClient.on('user-updated', onCurrentUserUpdate);
            zmClient.on('share-content-change', onShareContentChange);

            return () => {
                zmClient.off('active-share-change', onActiveShareChange);
                zmClient.off('user-updated', onCurrentUserUpdate);
                zmClient.off('share-content-change', onShareContentChange);
            };
        }
    }, [
        zmClient,
        onActiveShareChange,
        onCurrentUserUpdate,
        onShareContentChange
    ]);


    useEffect(() => {
        if (mediaStream) {
            const activeSharedUserId = mediaStream.getActiveShareUserId();
            if (activeSharedUserId) {
                setActiveSharingId(activeSharedUserId);
            }
        }
    }, [mediaStream]);


    return { activeSharingId, isStartedSelfShare };
}
