const app = {
    //APP CONSTANTS
    EVENT_MODE: 1,
    MEETING_MODE: 2,
    SESSION_FORMAT_1TO1: '1to1',
    SESSION_FORMAT_1TON: '1toN',
    SET_OKTA_AUTH_INSTANCE: 'SET_OKTA_AUTH_INSTANCE',
};

const controller = {
    // CONTROLLER CONSTANTS
    REMOVE_EVENT_LOGO: 'REMOVE_EVENT_LOGO',
    ADD_EVENT_LOGO: 'ADD_EVENT_LOGO',
    UPDATE_CURRENT_EVENT: 'UPDATE_CURRENT_EVENT',
    SET_APP_MODE: 'SET_APP_MODE',
    SET_DIRECT_MODE: 'SET_DIRECT_MODE',
    SET_BUBBLE_URL: 'SET_BUBBLE_URL',
    ALL_DATA_PROVIDED: 'ALL_DATA_PROVIDED',
    SET_CONTAINER_LOADED: 'SET_CONTAINER_LOADED',

    INIT_ROOM_CONTROLLER: 'INIT_ROOM_CONTROLLER',
    INIT_ROOM_CONTROLLER_SUCCESS: 'INIT_ROOM_CONTROLLER_SUCCESS',
    INIT_ROOM_CONTROLLER_FAILED: 'INIT_ROOM_CONTROLLER_FAILED',

    SWITCH_CURRENT_VIEW: "SWITCH_CURRENT_VIEW",
    SWITCH_FISHBOWL_VIEW: "SWITCH_FISHBOWL_VIEW",
    UNSET_FISHBOWL_VIEW: 'UNSET_FISHBOWL_VIEW',

    SET_ACCESS_GRANTED_ERROR_BLOCK_VISIBILITY: 'SET_ACCESS_GRANTED_ERROR_BLOCK_VISIBILITY',
    SET_DISCONNECTED_INFO: 'SET_DISCONNECTED_INFO',
    SET_PERSON_ICON_VISIBLE: 'SET_PERSON_ICON_VISIBLE',
    SET_INFO_BLOCK_VISIBILITY: 'SET_INFO_BLOCK_VISIBILITY',
    SET_ERROR_INFO_BLOCK_VISIBILITY: 'SET_ERROR_INFO_BLOCK_VISIBILITY',
    SET_MAX_MEMBERS_BLOCK_VISIBILITY: 'SET_MAX_MEMBERS_BLOCK_VISIBILITY',

    SET_CURRENT_EVENT: 'SET_CURRENT_EVENT',
    SET_CURRENT_USER_SESSION: 'SET_CURRENT_USER_SESSION',
    SET_SELECTED_NEXT_SESSION_VARIANT: 'SET_SELECTED_NEXT_SESSION_VARIANT',
    UPDATE_SESSION_END_TIME: 'UPDATE_SESSION_END_TIME',
    SESSION_HAS_BEEN_EXTENDED: 'SESSION_HAS_BEEN_EXTENDED',
    SET_SURVEY_SUBMIT_BUTTON: 'SET_SURVEY_SUBMIT_BUTTON',
    SET_PREVIOUS_SESSION_NUM: 'SET_PREVIOUS_SESSION_NUM',

    SHOW_NEXT_SESSION_POPUP: 'SHOW_NEXT_SESSION_POPUP',
    SHOW_RIGHT_FLYIN_POPUP: 'SHOW_RIGHT_FLYIN_POPUP',
    SHOW_FOUND_RIGHT_FLYIN: 'SHOW_FOUND_RIGHT_FLYIN',
    SHOW_3RD_PARTY_POPUP: 'SHOW_3RD_PARTY_POPUP',
    SHOW_FISHBOWL_POPUP: 'SHOW_FISHBOWL_POPUP',
    SHOW_SURVEY_POPUP: 'SHOW_SURVEY_POPUP',
    SHOW_NEXT_PARTNER_BIO_POPUP: 'SHOW_NEXT_PARTNER_BIO_POPUP',
    HIDE_NEXT_PARTNER_BIO_POPUP: 'HIDE_NEXT_PARTNER_BIO_POPUP',
    NEED_SHOW_RIGHT_FLYIN: 'NEED_SHOW_RIGHT_FLYIN',
    NEED_SHOW_SURVEY_POPUP: 'NEED_SHOW_SURVEY_POPUP',
    HIDE_SURVEY_POPUP: 'HIDE_SURVEY_POPUP',
    HIDE_JOIN_BY_3RD_PARTY: 'HIDE_JOIN_BY_3RD_PARTY',
    SHOW_EXTEND_CALL_ACTION: 'SHOW_EXTEND_CALL_ACTION',
    SHOW_WRAPUP_NOTES_POPUP: 'SHOW_WRAPUP_NOTES_POPUP',
    HIDE_WRAPUP_NOTES_POPUP: 'HIDE_WRAPUP_NOTES_POPUP',
    SHOW_WRAPUP_EMAIL_POPUP: 'SHOW_WRAPUP_EMAIL_POPUP',
    HIDE_WRAPUP_EMAIL_POPUP: 'HIDE_WRAPUP_EMAIL_POPUP',
    SHOW_EMAIL_POPUP: 'SHOW_EMAIL_POPUP',
    HIDE_EMAIL_POPUP: 'HIDE_EMAIL_POPUP',
    SET_EMAIL_POPUP_DATA: 'SET_EMAIL_POPUP_DATA',

    SET_VIDEO_BOTH_CONNECTED: 'SET_VIDEO_BOTH_CONNECTED',
    SET_VIDEO_DID_CONNECTED: 'SET_VIDEO_DID_CONNECTED',
    SET_VIDEO_CONNECTED: 'SET_VIDEO_CONNECTED',
    MARK_VIDEO_COMPLETED: 'MARK_VIDEO_COMPLETED',
    MARK_SESSION_COMPLETED: 'MARK_SESSION_COMPLETED',
    MARK_SESSION_DISCONNECTED: 'MARK_SESSION_DISCONNECTED',

    USER_ACCEPT_INVITE: 'USER_ACCEPT_INVITE',
    USER_DECLINE_INVITE: 'USER_DECLINE_INVITE',
    CLEAR_ACCEPTED_INVITE: 'CLEAR_ACCEPTED_INVITE',
    SET_PARTY_VIEW: 'SET_PARTY_VIEW',
    SET_EXTEND_CALL_BLOCKED: 'SET_EXTEND_CALL_BLOCKED',
    SET_EXTEND_CALL_DIRECT_MODE: 'SET_EXTEND_CALL_DIRECT_MODE',
    SET_EXTEND_CALL_BY_MINUTES: 'SET_EXTEND_CALL_BY_MINUTES',
    CLEAR_EXTEND_CALL_OPTIONS: 'CLEAR_EXTEND_CALL_OPTIONS',

    NEED_SOCKET_RECONNECT: 'NEED_SOCKET_RECONNECT',
    SET_IS_PUBLISH_PROBLEMS: 'SET_IS_PUBLISH_PROBLEMS',
    SET_SURVEY_SUCCESSFULLY_POSTED: 'SET_SURVEY_SUCCESSFULLY_POSTED',
    DISABLE_GREEN_SCREEN: 'DISABLE_GREEN_SCREEN',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_DISPLAY_PROFILE: 'SET_DISPLAY_PROFILE'
};

const room = {
    // ROOM CONSTANTS
    HIDE_JOIN_BLOCK: 'HIDE_JOIN_BLOCK',
    CLEAR_ROOM_INITIAL_PARAMS: 'CLEAR_ROOM_INITIAL_PARAMS',

    TRIGGER_CALL_ENDED: 'TRIGGER_CALL_ENDED',

    SET_DEVICE: 'SET_DEVICE',
    TOGGLE_FOOTER_CONTROLS_VISIBILITY: 'TOGGLE_FOOTER_CONTROLS_VISIBILITY',
    SET_DEVICE_CHANGING: 'SET_DEVICE_CHANGING',
    SET_SCREEN_SHARE_CANCELLED: 'SET_SCREEN_SHARE_CANCELLED',

    SET_FULLSCREEN: 'SET_FULLSCREEN',
    UNSET_FULLSCREEN: 'UNSET_FULLSCREEN',
    SET_CALL_RECORDED: 'SET_CALL_RECORDED',
    SET_JOIN_IS_CLICKED: 'SET_JOIN_IS_CLICKED',
    SET_IS_VISITOR_ALONE: 'SET_IS_VISITOR_ALONE',
    CALL_ENDED: 'CALL_ENDED',

    SET_PUBLISHER_IS_READY: 'SET_PUBLISHER_IS_READY',

    PARTNER_CONNECTED: 'PARTNER_CONNECTED',
    PARTNER_CLICKED_END_CALL: 'PARTNER_CLICKED_END_CALL',
    UNSET_VIDEO_HAS_BEEN_CONNECTED: 'UNSET_VIDEO_HAS_BEEN_CONNECTED',
    SET_END_CALL_CLICKED: 'SET_END_CALL_CLICKED',

    MEMBER_JOIN_CONFERENCE: 'MEMBER_JOIN_CONFERENCE',
    MEMBER_LEAVE_CONFERENCE: 'MEMBER_LEAVE_CONFERENCE',

    SET_MY_CHOICE: 'SET_MY_CHOICE',
    SET_OTHER_USER_CHOICE: 'SET_OTHER_USER_CHOICE',
    SET_OTHER_USER_B_CHOICE: 'SET_OTHER_USER_B_CHOICE',
    CLEAR_CHOICES: 'CLEAR_CHOICES',
    CALL_EXTENDED: 'CALL_EXTENDED',

    SET_OTHER_USER_SEEN_NEXT_SESSION_POPUP: 'SET_OTHER_USER_SEEN_NEXT_SESSION_POPUP',
    SET_OTHER_USER_B_SEEN_NEXT_SESSION_POPUP: 'SET_OTHER_USER_B_SEEN_NEXT_SESSION_POPUP',
    UNSET_USER_SEEN_NEXT_SESSION_POPUP: 'UNSET_USER_SEEN_NEXT_SESSION_POPUP',
    SET_IS_OPEN_EDIT_BREAKS_POPUP: 'SET_IS_OPEN_EDIT_BREAKS_POPUP',
    SET_USER_SESSIONS_ACTION: 'SET_USER_SESSIONS_ACTION'
};

const fishbowl = {
    // FISHBOWL MODAL CONSTANTS

    SET_FISHBOWL_BUTTON_SELECTED: 'SET_FISHBOWL_BUTTON_SELECTED',
    SWITCH_FISHBOWL_PARTY_VIEW: 'SWITCH_FISHBOWL_PARTY_VIEW',
    SET_FALSE_FIRST_TIME_SEARCH_STATE: 'SET_FALSE_FIRST_TIME_SEARCH_STATE',
    CLEAR_FISHBOWL_OPTIONS: 'CLEAR_FISHBOWL_OPTIONS',

    SET_SIDEBAR_TAB: 'SET_SIDEBAR_TAB',
};

const users = {
    // USERS CONSTANTS
    SET_USER_DEFAULT_ACCOUNT: 'SET_USER_DEFAULT_ACCOUNT',
    SET_MIC_ONLY_MODE: 'SET_MIC_ONLY_MODE',
    SET_TEST_PERMIT_MOBILE: 'SET_TEST_PERMIT_MOBILE',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_OTHER_USER: 'SET_OTHER_USER',
    SET_OTHER_USER_B: 'SET_OTHER_USER_B',
    SET_INVITED_USER: 'SET_INVITED_USER',
    SET_NEXT_PARTNER: 'SET_NEXT_PARTNER',
    UNSET_NEXT_PARTNER: 'UNSET_NEXT_PARTNER',
    SET_ACCOUNT_CUSTOMIZATION: 'SET_ACCOUNT_CUSTOMIZATION',
    SET_PREVIOUS_USERS: 'SET_PREVIOUS_USERS',
    SET_NEXT_USERS: 'SET_NEXT_USERS',
    SAVE_NOTES: 'SAVE_NOTES',
    RESET_PARTNER_BIO_PROGRESSES_FETCHED: 'RESET_PARTNER_BIO_PROGRESSES_FETCHED',
    SET_PARTNER_BIO_PROGRESSES: 'SET_PARTNER_BIO_PROGRESSES',
    SET_PARTNER_COMPLETE_PRIOR_CALL: 'SET_PARTNER_COMPLETE_PRIOR_CALL',
    SET_PARTNER_COMPLETE_SURVEY: 'SET_PARTNER_COMPLETE_SURVEY',
    SET_PARTNER_COMPLETE_JOINING: 'SET_PARTNER_COMPLETE_JOINING',
    SET_PARTNER_COMPLETE_MATCHES: 'SET_PARTNER_COMPLETE_MATCHES',
    CLEAR_INVOICE: 'CLEAR_INVOICE',
    SET_INITIALIZED_LOGROCKET: 'SET_INITIALIZED_LOGROCKET',
    SET_IDENTIFIED_LOGROCKET: 'SET_IDENTIFIED_LOGROCKET',
};

const test = {
    //TEST CONSTANTS
    SET_USER_ID: 'SET_USER_ID',
    SET_TEST_DIRECT_MODE: 'SET_TEST_DIRECT_MODE',
    SET_PROCEED_URL: 'SET_PROCEED_URL',
    SET_STAGE: 'SET_STAGE',
    SET_SUCCESS: 'SET_SUCCESS',
    SET_FAILED: 'SET_FAILED',
    SET_WARNING: 'SET_WARNING',
    SET_IN_PROGRESS: 'SET_IN_PROGRESS',
    SET_PROGRESS_MESSAGE: 'SET_PROGRESS_MESSAGE',
    SET_STEP_PROGRESS: 'SET_STEP_PROGRESS',
    SET_STEP_STATE: 'SET_STEP_STATE',
    SET_STEP_COUNTER: 'SET_STEP_COUNTER',
    SET_EQUIPMENT_MODAL_TYPE: 'SET_EQUIPMENT_MODAL_TYPE',
    SHOW_EQUIPMENT_MODAL: 'SHOW_EQUIPMENT_MODAL',
    SET_TEST_MIC_ONLY_MODE: 'SET_TEST_MIC_ONLY_MODE',
    SET_TEST_PARENT_URL: 'SET_TEST_PARENT_URL',
    SET_TEST_WITHOUT_WEBCAM: 'SET_TEST_WITHOUT_WEBCAM',
    SHOW_WITHOUT_WEBCAM_BUTTON: 'SHOW_WITHOUT_WEBCAM_BUTTON',
    SHOW_SUPPORT: 'SHOW_SUPPORT',
    SET_FAQ_ITEMS: 'SET_FAQ_ITEMS',
    SET_VIDEO_LOADED: 'SET_VIDEO_LOADED',
    SET_MIC_SELECTED: 'SET_MIC_SELECTED',
    SET_MIC_SELECT_OPTIONS: 'SET_MIC_SELECT_OPTIONS',
    SET_WEBCAM_SELECTED: 'SET_WEBCAM_SELECTED',
    SET_WEBCAM_SELECT_OPTIONS: 'SET_WEBCAM_SELECT_OPTIONS',
    SLIDE_WEBCAM_FLOW: 'SLIDE_WEBCAM_FLOW',
};

const checkin = {
    // CHECK IN FLOW
    UPDATE_EVENT_REGISTRATION_ORGANIZER_MODE: 'UPDATE_EVENT_REGISTRATION_ORGANIZER_MODE',
    UPDATE_EVENT_REGISTRATION: 'UPDATE_EVENT_REGISTRATION',
    SET_USER_LOGGED_IN_ACTION: 'SET_USER_LOGGED_IN_ACTION',
    SET_CHECKIN_STEPS: 'SET_CHECKIN_STEPS',
    SET_CHECKIN_OPTIONS: 'SET_CHECKIN_OPTIONS',
    SET_USER_ONLINE: 'SET_USER_ONLINE',
    SET_CHECK_IN_READY: 'SET_CHECK_IN_READY',
    SET_EVENT_REGISTRATION_INFO: 'SET_EVENT_REGISTRATION_INFO',
    SET_EVENT_REGISTRATIONS: 'SET_EVENT_REGISTRATIONS',
    UPDATE_CHECK_IN_CURRENT_STEP: 'UPDATE_CHECK_IN_CURRENT_STEP',
    SET_CURRENT_EVENT_SESSIONS: 'SET_CURRENT_EVENT_SESSIONS',
    SET_CURRENT_SESSION_NUMBER: 'SET_CURRENT_SESSION_NUMBER',
    SET_CHECKIN_FISHBOWL_POPUP: 'SET_CHECKIN_FISHBOWL_POPUP',
    SET_CHECKIN_FISHBOWL_MINI_POPUP: 'SET_CHECKIN_FISHBOWL_MINI_POPUP',
    SET_FISHBOWL_MINI_SEARCH_MODE: 'SET_FISHBOWL_MINI_SEARCH_MODE',
    SET_TARGET_JOIN_POPUP: 'SET_TARGET_JOIN_POPUP',
    SET_TARGET_JOIN_MINI_POPUP: 'SET_TARGET_JOIN_MINI_POPUP',
    HIDE_CHECKIN_FISHBOWL_POPUP: 'HIDE_CHECKIN_FISHBOWL_POPUP',
    HIDE_TARGET_JOIN_POPUP: 'HIDE_TARGET_JOIN_POPUP',
    SET_FISHBOWL_FUNCTIONALITY_BLOCKED: 'SET_FISHBOWL_FUNCTIONALITY_BLOCKED',
    SET_NEED_SHOW_GREEN_SCREEN: 'SET_NEED_SHOW_GREEN_SCREEN',
    SET_CONTROLLER_STORE: 'SET_CONTROLLER_STORE',
    SET_ROOM_STORE: 'SET_ROOM_STORE',
    SET_USERS_STORE: 'SET_USERS_STORE',
    SET_FISHBOWL_STORE: 'SET_FISHBOWL_STORE',
    SET_ORGANIZER_VIDEO: 'SET_ORGANIZER_VIDEO',
    SET_AVAILABLE_TO_MATCH: 'SET_AVAILABLE_TO_MATCH',
    SET_DETECT_RTC_DATA: 'SET_DETECT_RTC_DATA',
    UPDATE_EVENT_REGISTRATIONS_EVENT_TAGS: 'UPDATE_EVENT_REGISTRATIONS_EVENT_TAGS',
    SET_PREVIOUS_MET_USERS_IDS: 'SET_PREVIOUS_MET_USERS_IDS',
    SET_ONLINE_USERS_IDS: 'SET_ONLINE_USERS_IDS',
    SET_KICKOFF_IS_OVER: 'SET_KICKOFF_IS_OVER',
    SET_KICKOFF_IS_HIDDEN: 'SET_KICKOFF_IS_HIDDEN',
    SET_LAST_MEET_OVER: 'SET_LAST_MEET_OVER',
    SET_LAST_MEET_PASSED: 'SET_LAST_MEET_PASSED'
};

const registration = {
    SET_REGISTRATION_STEP: 'SET_REGISTRATION_STEP',
    SET_SELECTED_TICKET: 'SET_SELECTED_TICKET',
    SHOW_REGISTRATION_TICKETS_POPUP: 'SHOW_REGISTRATION_TICKETS_POPUP',
    HIDE_REGISTRATION_TICKETS_POPUP: 'HIDE_REGISTRATION_TICKETS_POPUP',
    SET_CREATE_REGISTRATION_ON_FLY: 'SET_CREATE_REGISTRATION_ON_FLY',
    SET_LOGGED_IN_SYSTEM: 'SET_LOGGED_IN_SYSTEM',
    SET_LOADING: 'SET_LOADING',
    ADD_BULK_ATTENDEES: 'ADD_BULK_ATTENDEES',
    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
    SET_SUCCESS_MESSAGE: 'SET_SUCCESS_MESSAGE',
    SET_DIFFERENCE_TIME: 'SET_DIFFERENCE_TIME',
};

const organizer = {
    SET_USER: 'SET_USER',
    SET_INITIAL_NAV_ITEMS: 'SET_INITIAL_NAV_ITEMS',
    SET_OPEN_SIDEBAR: 'SET_OPEN_SIDEBAR',
    SET_DEFAULT_SURVEY: 'SET_DEFAULT_SURVEY',
    SET_EDITABLE_SURVEY: 'SET_EDITABLE_SURVEY',
    SET_EDITABLE_WRAPUP_SURVEY: 'SET_EDITABLE_WRAPUP_SURVEY',
    SET_CUSTOM_WRAPUP_SURVEY: 'SET_CUSTOM_WRAPUP_SURVEY',
    SET_CUSTOM_WRAPUP_SURVEY_QUESTIONS: 'SET_CUSTOM_WRAPUP_SURVEY_QUESTIONS',
    SET_CO_ORGANIZERS: 'SET_CO_ORGANIZERS',
    SET_CO_ORGANIZERS_LOADING: 'SET_CO_ORGANIZERS_LOADING',
    SET_CUSTOM_WRAPUP_SURVEYS: 'SET_CUSTOM_WRAPUP_SURVEYS',
    UPDATE_CUSTOM_WRAPUP_SURVEY: 'UPDATE_CUSTOM_WRAPUP_SURVEY',
    CREATE_CUSTOM_WRAPUP_SURVEY: 'CREATE_CUSTOM_WRAPUP_SURVEY',
    SET_SHOW_EXPLANATION_AFTER_CREATE_EVENT: 'SET_SHOW_EXPLANATION_AFTER_CREATE_EVENT',
    SET_IS_MENU_ADMIN_OPEN: 'SET_IS_MENU_ADMIN_OPEN',
    SET_IS_SHOW_DELETE_EVENT_MODAL: 'SET_IS_SHOW_DELETE_EVENT_MODAL',
    SET_IS_SHOW_COPY_TEMPLATE_KEY_MODAL: 'SET_IS_SHOW_COPY_TEMPLATE_KEY_MODAL',
    SET_TEMPLATE_KEY: 'SET_TEMPLATE_KEY',
    SET_INTRODUCTIONS_MADE_COUNT: 'SET_INTRODUCTIONS_MADE_COUNT',
    SET_ALL_EVENT_REGISTRATIONS_COUNT: 'SET_ALL_EVENT_REGISTRATIONS_COUNT',
    SET_ATTENDING_EVENT_REGISTRATIONS_COUNT: 'SET_ATTENDING_EVENT_REGISTRATIONS_COUNT',
    SET_LAST_FIVE_REGISTRATIONS: 'SET_LAST_FIVE_REGISTRATIONS',
    SET_ATTENDING_CHECK_IN_EVENT_REGISTRATIONS_COUNT: 'SET_ATTENDING_CHECK_IN_EVENT_REGISTRATIONS_COUNT',
    SET_SPONSOR_COUNT: 'SET_SPONSOR_COUNT',
    SET_EMAILS_TEMPLATES_DEFAULT: 'SET_EMAILS_TEMPLATES_DEFAULT',
    SET_EVENT_REMINDER: 'SET_EVENT_REMINDER',
    SET_FEATURES_TIER_LEVEL: 'SET_FEATURES_TIER_LEVEL',
    SET_SHOW_WARNING_COPY_POPUP: 'SET_SHOW_WARNING_COPY_POPUP'
};

const preview = {
    SET_INITIAL_STATE_PREVIEW: 'SET_INITIAL_STATE_PREVIEW',
    SET_SELF_EVENT_TAGS: 'SET_SELF_EVENT_TAGS',
    SET_EDITABLE_PREVIEW: 'SET_EDITABLE_PREVIEW',
    ADD_MESSAGE: 'ADD_MESSAGE'
};

const home = {
    SET_HOME_PAGE_ACCOUNTS: 'SET_HOME_PAGE_ACCOUNTS',
    SET_HOME_PAGE_EVENTS: 'SET_HOME_PAGE_EVENTS',
    SET_HOME_PAGE_TICKETS: 'SET_HOME_PAGE_TICKETS',
    SET_INIT_LOGOUT_OTHER_BROWSERS: 'SET_INIT_LOGOUT_OTHER_BROWSERS',
    SET_TEAMS: 'SET_TEAMS',
    SET_IS_OPEN_PAYMENT_MODAL: 'SET_IS_OPEN_PAYMENT_MODAL',
    SET_PAYMENT_DATA: 'SET_PAYMENT_DATA',
    SET_NEW_TEMPLATE_EVENT_ID: 'SET_NEW_TEMPLATE_EVENT_ID'
};

const zoom = {
    INIT_ZOOM_PARTICIPANTS: 'INIT_ZOOM_PARTICIPANTS',
    ADD_ZOOM_PARTICIPANTS: 'ADD_ZOOM_PARTICIPANTS',
    UPDATE_ZOOM_PARTICIPANTS: 'UPDATE_ZOOM_PARTICIPANTS',
    DISCONNECT_ZOOM_PARTICIPANTS: 'DISCONNECT_ZOOM_PARTICIPANTS',
    SET_ZOOM_ROOM_NAME: 'SET_ZOOM_ROOM_NAME',
    SET_ZOOM_ROOM_TYPE: 'SET_ZOOM_ROOM_TYPE',
    SET_ZOOM_IS_FETCHING: 'SET_ZOOM_IS_FETCHING',
    SET_ZOOM_ERROR: 'SET_ZOOM_ERROR',
    SET_ZOOM_CLIENT: 'SET_ZOOM_CLIENT',
    SET_ZOOM_IS_BACKGROUND_SECTION_OPEN: 'SET_ZOOM_IS_BACKGROUND_SECTION_OPEN',
    INIT_ZOOM_WEBINAR_PARTICIPANTS: 'INIT_ZOOM_WEBINAR_PARTICIPANTS',
    ADD_ZOOM_WEBINAR_PARTICIPANTS: 'ADD_ZOOM_WEBINAR_PARTICIPANTS',
    UPDATE_ZOOM_WEBINAR_PARTICIPANTS: 'UPDATE_ZOOM_WEBINAR_PARTICIPANTS',
    DISCONNECT_ZOOM_WEBINAR_PARTICIPANTS: 'DISCONNECT_ZOOM_WEBINAR_PARTICIPANTS',
    ZOOM_WEBINAR_SET_CHAT_TOKEN: 'ZOOM_WEBINAR_SET_CHAT_TOKEN',
};

const webinar = {
    SET_WEBINAR_FULL_SCREEN: 'SET_WEBINAR_FULL_SCREEN',
    SET_WEBINAR_PARTICIPANT_TYPE: 'SET_WEBINAR_PARTICIPANT_TYPE',
    SET_WEBINAR_PARTICIPANT_NAME: 'SET_WEBINAR_PARTICIPANT_NAME',
    SET_WEBINAR_EVENT_NAME: 'SET_WEBINAR_EVENT_NAME',
    SET_WEBINAR_IS_OPEN_FLY_IN_SIDEBAR: 'SET_WEBINAR_IS_OPEN_FLY_IN_SIDEBAR',
    SET_WEBINAR_FLY_IN_USER_PROFILE: 'SET_WEBINAR_FLY_IN_USER_PROFILE',
    SET_WEBINAR_SET_LOADING: 'SET_WEBINAR_SET_LOADING',
    SET_WEBINAR_SET_STARTED_TIME: 'SET_WEBINAR_SET_STARTED_TIME',
    SET_WEBINAR_CLOSED: 'SET_WEBINAR_CLOSED',
    SET_WEBINAR_IS_BACKGROUND_SECTION_OPEN: 'SET_WEBINAR_IS_BACKGROUND_SECTION_OPEN',
    SET_STREAM_PLAYBACK_ID: 'SET_STREAM_PLAYBACK_ID',
    SET_IS_NEED_UNMUTE: 'SET_IS_NEED_UNMUTE',
    SET_STREAM_LATENCY: 'SET_STREAM_LATENCY',
    SET_IS_LAST_WEBINAR_ACTION: 'SET_IS_LAST_WEBINAR_ACTION',
    SET_WEBINAR_RAISED_HANDS: 'SET_WEBINAR_RAISED_HANDS',
    SET_IS_CHAT_WINDOW_OPEN: 'SET_IS_CHAT_WINDOW_OPEN',
};

const event = {
    SET_EVENTS_DATA: 'SET_EVENTS_DATA',
    SET_EVENTS_LOADING: 'SET_EVENTS_LOADING',
    SET_EVENT_ERROR_RESPONSE: 'SET_EVENT_ERROR_RESPONSE',
    SET_LOADED_CONTAINER: 'SET_LOADED_CONTAINER',
};

const system = {
    SET_SYSTEM_SETTINGS: 'SET_SYSTEM_SETTINGS',
};

export default {
    ...app, ...controller, ...room, ...users, ...fishbowl, ...test, ...event,
    ...checkin, ...registration, ...organizer, ...preview, ...home,
    ...system, ...webinar, ...zoom
};
