import cookies from "js-cookie";
import config from "./config";

export const logout = (callback = () => {}) => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('limited_token');
    localStorage.removeItem('run_as_jwt_token');
    localStorage.removeItem('fb_token');

    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-shared-transaction-storage');
    localStorage.removeItem('okta-original-uri-storage');
    localStorage.removeItem('okta-cache-storage');

    cookies.remove('jwt_token', { path: '/', domain: '.meetaway.com' });
    cookies.remove('run_as_jwt_token', { path: '/', domain: '.meetaway.com' });
    cookies.remove('limited_token', { path: '/', domain: '.meetaway.com' });

    const isWrapup = document.location.href.includes('wrapup');
    if (!isWrapup) document.location.reload();

    const {pathname} = window.document.location;
    const urlsRedirectToBubble = ['/', '/home', '/create-event', '/settings'];

    if (urlsRedirectToBubble.includes(pathname) || pathname.includes('/m')) {
        return document.location = pathname.includes('/home')
            ? '/'
            : `${config.BUBBLE_MAIN_URL}`;
    }

    if (pathname && pathname.includes('/403')) {
        return document.location = `${config.VIDEOSYSTEM_MAIN_URL}`;
    }

    callback();
    return document.location.reload();
    //return store.dispatch(clearUserState());
};

export const setSystemToken = (name, token) => {
    if (name !== 'run_as_auth_token' && name !== 'run_as_jwt_token') {
        cookies.set(name, token, {path: '/', domain: '.meetaway.com', expires: 365});
    }
    localStorage.setItem(name, JSON.stringify({value: token}));
};

export const getSystemToken = (name) => {
    let token;
    try {
        token = JSON.parse(localStorage.getItem(name));
        if (!token || !token.value) {
            throw new Error(`No found token (localStorage): ${name}`);
        }
    } catch (error) {
        //console.warn(error);
        // Handle the error gracefully, maybe fallback to an alternative method
        token = {
            value: cookies.get(name)
        };
    }

    return token && token.value ? token.value : null;
};
