import React, { createContext, useCallback, useEffect, useState } from "react";
import ZoomVideo, { MobileVideoFacingMode } from "@zoom/videosdk";
import _ from "lodash";
import DetectRTC from "detectrtc";

import store from "store";
import useZoomClient from "./useZoomClient/useZoomClient";
import deviceIdStorage from "../../../../utils/deviceIdStorage";
import { dispatchError, showAccessGrantedErrorBlock, showErrorInfoBlock } from "../../../../store/zoom/actions";
import { errorReasons } from "../../../../utils/constants";
import { setMicOnlyModeAction } from "../../../../store/users/actions";
import { getBackgroundSettings, getImagePath, isMobile, isSupportedVB } from "../../utils/util";
import { isAndroidOrIOSBrowser } from "../../utils/platform";
import { stopStream } from "../../../../utils/helpers";


export const VideoContext = createContext(null);

export function VideoProvider({ children, zmClient }) {
    const [horizontablePosition, setHorizontablePosition] = useState(false);
    const [isStartedLocalVideo, setIsStartedLocalVideo] = useState(false);

    const {
        mediaContext,
        isConnecting,
        isConnected,
        isSupportGalleryView,
        zoomConnect,
        loading,
        connectionStatus,
        rerenderParticipant,
        isStartShareScreenWithVideoElement,
    } = useZoomClient({ zmClient });

    useEffect(() => {
        let timeoutId;

        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async () => {
                const isHorizontable = window.matchMedia("(orientation: landscape)").matches;
                setHorizontablePosition(isHorizontable);
                await rerenderParticipant();
            }, 500);
        };

        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const requestMediaPermissionsIfNeeded = async () => {
        try {
            const { micOnlyMode } = store.getState().users;
            if (DetectRTC.browser.isFirefox || DetectRTC.browser.isSafari || DetectRTC.osName === "Android") {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: !micOnlyMode,
                    audio: true
                });
                await stopStream(stream);
            } else {
                const cameraPermission = await navigator.permissions.query({
                    name: "camera"
                });
                const microphonePermission = await navigator.permissions.query({
                    name: "microphone"
                });
                if (cameraPermission.state === "prompt" || microphonePermission.state === "prompt") {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: !micOnlyMode,
                        audio: true
                    });
                    await stopStream(stream);
                }
            }
        } catch (error) {
            console.error("requestMediaPermissionsIfNeeded:", error);
            const { micOnly } = store.getState().controller.currentEvent;
            if (micOnly) {
                store.dispatch(setMicOnlyModeAction());
            }
        }
    };

    const checkDevices = async () => {
        try {
            const allDevices = await ZoomVideo.getDevices();
            const videoAccessIssue = !allDevices.some((device) => {
                return device.kind === "videoinput";
            });
            const audioAccessIssue = !allDevices.some((device) => {
                return device.kind === "audioinput";
            });
            console.log('devices', {
                allDevices, videoAccessIssue, audioAccessIssue
            });

            const { micOnly } = store.getState().controller.currentEvent;
            if (videoAccessIssue && micOnly) {
                store.dispatch(setMicOnlyModeAction());
            }
            if (audioAccessIssue || (videoAccessIssue && !micOnly)) {
                store.dispatch(showErrorInfoBlock(errorReasons["REASON_ACCESS_DENIED"]));
            }
        } catch (e) {
            console.error("checkDevices", e.reason);
            store.dispatch(dispatchError(e));
        }
    };

    const getStoredDevices = (mediaStream) => {
        try {
            const videoInputDevices = mediaStream?.getCameraList() ?? [];
            const audioInputDevices = mediaStream?.getMicList() ?? [];
            const audioOutputDevices = mediaStream?.getSpeakerList() ?? [];

            const videoInputDeviceId = _.get(videoInputDevices, "[0].deviceId", undefined);
            const audioInputDeviceId = _.get(audioInputDevices, "[0].deviceId", undefined);
            const audioOutputDeviceId = _.get(audioOutputDevices, "[0].deviceId", undefined);

            let videoDeviceId = deviceIdStorage.getVideoDeviceId() || videoInputDeviceId;
            let micDeviceId = deviceIdStorage.getAudioDeviceId() || audioInputDeviceId;
            let speakerDeviceId = deviceIdStorage.getAudioOutputDeviceId() || audioOutputDeviceId;

            console.log('storedDevices', {
                videoInputDevices, audioInputDevices, audioOutputDevices,
                videoDeviceId, micDeviceId, speakerDeviceId
            });

            const isExistVideoDeviceId =
                videoInputDevices.some(({ deviceId }) => deviceId === videoDeviceId) ||
                (isAndroidOrIOSBrowser() && (videoDeviceId === MobileVideoFacingMode.User || videoDeviceId === MobileVideoFacingMode.Environment));
            const isExistMicDeviceId = audioInputDevices.some(({ deviceId }) => deviceId === micDeviceId);
            const isExistSpeakerDeviceId = audioOutputDevices.some(({ deviceId }) => deviceId === speakerDeviceId);

            if (!isExistVideoDeviceId) {
                videoDeviceId = undefined;
            }
            if (!isExistMicDeviceId) {
                micDeviceId = undefined;
            }
            if (!isExistSpeakerDeviceId || isAndroidOrIOSBrowser() || DetectRTC.browser.isFirefox || DetectRTC.browser.isSafari) {
                speakerDeviceId = undefined;
            }

            return {
                videoDeviceId,
                micDeviceId,
                speakerDeviceId
            }
        } catch (e) {
            console.error("getStoredDevices", e.reason);
            store.dispatch(dispatchError(e));
        }
    };

    const startVideo = async (mediaStream, imageUrl) => {
        try {
            const backgroundSettings = getBackgroundSettings();
            const { videoDeviceId } = getStoredDevices(mediaStream);
            const { system: { virtualBackground } } = store.getState();

            const startVideoOptions = {
                originalRatio: isMobile,
                cameraId: videoDeviceId,
            };

            if (mediaStream?.isSupportHDVideo() && !isMobile) {
                Object.assign(startVideoOptions, {
                    hd: true
                });
            }

            if (isSupportedVB && mediaStream?.isSupportVirtualBackground() && virtualBackground) {
                Object.assign(startVideoOptions, {
                    virtualBackground: {
                        imageUrl: imageUrl ?? getImagePath(backgroundSettings)
                    }
                });
            }

            await mediaStream?.startVideo(startVideoOptions);
        } catch (e) {
            console.error("startVideo", e);
            store.dispatch(dispatchError(e));

            const { micOnly } = store.getState().controller.currentEvent;
            if (micOnly) {
                store.dispatch(setMicOnlyModeAction());
            }
        }
    };

    const startAudio = async (mediaStream) => {
        try {
            const { micDeviceId, speakerDeviceId } = getStoredDevices(mediaStream);
            const startAudioOptions = {
                microphoneId: micDeviceId,
                speakerId: speakerDeviceId
            };

            if (window.safari !== undefined) {
                Object.assign(startAudioOptions, {
                    autoStartAudioInSafari: true
                });
            }

           await mediaStream?.startAudio(startAudioOptions);
        } catch (e) {
            if (e.reason === "USER_FORBIDDEN_MICROPHONE") {
                store.dispatch(showAccessGrantedErrorBlock(errorReasons["REASON_ACCESS_DENIED"]));
            }
            console.error("startAudio", e);
            store.dispatch(dispatchError(e));
        }
    }

    const startCameraAndMicrophone = useCallback(async () => {
        const { mediaStream } = mediaContext;

        await requestMediaPermissionsIfNeeded();

        setTimeout(async ()=> {
            try {
                await checkDevices();
                await startAudio(mediaStream);
                await startVideo(mediaStream);
                await rerenderParticipant(zmClient.getSessionInfo().userId);
            } finally {
                setIsStartedLocalVideo(true);
            }
        }, 500);
    }, [mediaContext, zmClient]);

    return (
        <VideoContext.Provider
            value={{
                mediaContext,
                isConnecting,
                isConnected,
                isSupportGalleryView,
                zoomConnect,
                startCameraAndMicrophone,
                startVideo,
                startAudio,
                loading,
                connectionStatus,
                horizontablePosition,
                rerenderParticipant,
                isStartShareScreenWithVideoElement,
                isStartedLocalVideo
            }}
        >
            {children}
        </VideoContext.Provider>
    );
}
