import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";

import store from "store";
import { sendZoomStatistics, showErrorInfoBlock } from "store/zoom/actions";
import { setErrorInfoBlockVisibleAction } from "store/controller/actions";
import { errorReasons } from "utils/constants";
import { roundNumber } from "utils/helpers";


export function useNetworkQuality(zmClient) {
    const [networkQuality, setNetworkQuality] = useState({});
    const networkQualityLevelDataRef = useRef([]);
    const checkAverageValueTimerRef = useRef(null);

    const onNetworkChange = useCallback((payload) => {
        const { userId, type, level } = payload;
        const currentUserId = zmClient.getSessionInfo().userId;

        if (currentUserId === userId) {
            const data = {
                date: Date.now(),
                networkQualityLevel: level
            };

            if (level === 0) {
                store.dispatch(sendZoomStatistics({
                    networkQualityLevelData: data,
                    method: 'FE useParticipantNetworkQualityLevel',
                    message: 'NetworkQualityLevel is equal to 0',
                }, 'Network Quality Level'));
            }

            networkQualityLevelDataRef.current = [
                ...networkQualityLevelDataRef.current,
                data
            ];
        }

        setNetworkQuality(produce((draft) => {
            if (draft[`${userId}`] !== undefined) {
                if (type === 'uplink') {
                    draft[`${userId}`].uplink = level;
                }
                else {
                    draft[`${userId}`].downlink = level;
                }
            }
            else {
                Object.assign(draft, {
                    [`${userId}`]: {
                        [`${type}`]: level
                    }
                });
            }
        }));
    }, [zmClient]);

    useEffect(() => {
        if (zmClient) {
            zmClient.on("network-quality-change", onNetworkChange);
        }

        checkAverageValueTimerRef.current = window.setInterval(()=>{
            const networkQualityLevelData = networkQualityLevelDataRef.current;
            const firstData = networkQualityLevelData[0] ?? null;
            if (networkQualityLevelDataRef && firstData) {
                const sum = networkQualityLevelData.reduce((accumulator, {networkQualityLevel}) => (
                    accumulator + networkQualityLevel
                ), 0);
                const averageValue = roundNumber(sum / networkQualityLevelData.length, 2);

                console.log('Test Network Quality: ', {networkQualityLevelData, sum, averageValue});
                const {controller: {errorInfoBlock, errorInfoBlockReasons}} = store.getState();

                if (averageValue <= 2) {
                    store.dispatch(showErrorInfoBlock(errorReasons["REASON_NETWORK_UNSTABLE"]));
                    store.dispatch(sendZoomStatistics({
                        averageValue, networkQualityLevelData,
                        method: 'FE useParticipantNetworkQualityLevel',
                        message: 'Your internet connection is unstable.',
                    }, 'Network Quality Level'));
                } else if (errorInfoBlock && errorInfoBlockReasons && errorInfoBlockReasons.networkWarning) {
                    store.dispatch(setErrorInfoBlockVisibleAction(false, {
                        ...errorInfoBlockReasons, networkWarning: false
                    }));
                }
                networkQualityLevelDataRef.current = [];
            }
        }, 15000);

        return () => {
            if (checkAverageValueTimerRef.current) {
                clearInterval(checkAverageValueTimerRef.current);
            }
            if (zmClient) {
                zmClient.off('network-quality-change', onNetworkChange);
            }
        };
    }, [zmClient, onNetworkChange]);

    return networkQuality;
}
