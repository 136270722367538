export default {
    NODE_API_URL: process.env.REACT_APP_NODE_API_URL,
    BUBBLE_MAIN_URL: process.env.REACT_APP_BUBBLE_MAIN_URL,
    VIDEOSYSTEM_MAIN_URL: process.env.REACT_APP_VIDEOSYSTEM_MAIN_URL,
    LOCAL_SOCKET_URL: process.env.REACT_APP_LOCAL_SOCKET_URL,
    APP_ENV: process.env.REACT_APP_ENV || 'production',
    LOG_ROCKET_APP_ID: process.env.REACT_APP_LOG_ROCKET_APP_ID || null,
    LOG_ROCKET_MUX_ROOM_APP_ID: process.env.REACT_APP_LOG_ROCKET_MUX_ROOM_APP_ID || null,
    LOG_ROCKET_ROOM_APP_ID: process.env.REACT_APP_LOG_ROCKET_ROOM_APP_ID || null,
    LOG_ROCKET_ZOOM_ROOM_APP_ID: process.env.REACT_APP_LOG_ROCKET_ZOOM_ROOM_APP_ID || null,
    LOG_ROCKET_REGISTRATION_APP_ID: process.env.REACT_APP_LOG_ROCKET_REGISTRATION_APP_ID || null,
    LOG_ROCKET_CHECKIN_APP_ID: process.env.REACT_APP_LOG_ROCKET_CHECKIN_APP_ID || null,
    LOG_ROCKET_DIRECTORY_APP_ID: process.env.REACT_APP_LOG_ROCKET_DIRECTORY_APP_ID || null,
    LOG_ROCKET_ORGANIZER_APP_ID: process.env.REACT_APP_LOG_ROCKET_ORGANIZER_APP_ID || null,
    LOG_ROCKET_SETTINGS_APP_ID: process.env.REACT_APP_LOG_ROCKET_SETTINGS_APP_ID || null,
    CHROME_EXTENSION_ID: process.env.REACT_APP_CHROME_EXTENSION_ID,
    CHECK_IN_SECRET: process.env.REACT_APP_CHECK_IN_SECRET || '',
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
    LOCALIZE_API_KEY: process.env.REACT_APP_LOCALIZE_API_KEY || '',
    ZIGGEO_TOKEN: process.env.REACT_APP_ZIGGEO_TOKEN || '',
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY || '',
    AC_ACCOUNT: process.env.REACT_APP_AC_ACCOUNT || '',
    LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
    LINKEDIN_SECRET_ID: process.env.REACT_APP_LINKEDIN_SECRET_ID || '',
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID || '',
    SENDGRID_BUSINESS_ADRDRESS: process.env.REACT_APP_SENDGRID_BUSINESS_ADRDRESS || '665 Washington St. #1615, Boston, MA 02111',
    SENDGRID_BUSINESS_NAME: process.env.REACT_APP_SENDGRID_BUSINESS_NAME || 'Meetaway',
    SENDGRID_DOMAIN: process.env.REACT_APP_SENDGRID_DOMAIN || 'video.meetaway.com',
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
    SHOW_REDUX_LOGGER: process.env.REACT_APP_SHOW_REDUX_LOGGER || false,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
}
