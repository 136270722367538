import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { faImage, faMicrophone, faTimes, faTv, faVideo, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileVideoFacingMode, ShareStatus } from "@zoom/videosdk";

import { setIsBackgroundSelectionOpen } from "store/zoom/actions";
import { isMobile, isSupportedVB } from "../../utils/util";
import { getUnique } from "../../../../utils/helpers";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { SELF_SHARE_CANVAS, SELF_SHARE_VIDEO } from "../../utils/videoConstants";
import { isAndroidOrIOSBrowser } from "../../utils/platform";
import { useMount } from "../../hooks/useUnmount/useUnmount";
import deviceIdStorage from "../../../../utils/deviceIdStorage";

const whiteBorder = `1px solid gray`;

export const Wrapper = styled.div`
  color: #fff;
  width: ${isMobile ? '216px' : '298px'};
  background: rgba(0, 0, 0, 1);
  border-radius: 8px 8px 4px 4px;
  box-shadow: 2px 1px 5px 3px black;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 8px 10px 8px 15px;
  position: relative;
  display: flex;
  border-radius: 8px 8px 0 0;
  border-bottom: ${whiteBorder};
  background-color: #000;
  overflow: hidden;
  color: #b1b0b0;

  svg {
    cursor: pointer;
    margin-left: auto;
  }
`;

const MenuItem = styled.div`
  box-sizing: border-box;
  padding: 6px 10px 6px 18px;
  color: #b1b0b0;
  font-size: 14px;
  cursor: ${pr => pr.isDisabled ? 'not-allowed' : 'pointer'};

  &:hover {
    color: #fff;
  }
`;

const SectionTitle = styled.div`
  color: ${pr => pr.isGray ? "#6e6d6d" : '#b1b0b0'};
  font-size: 14px;
  padding: 0 10px 10px 18px;
`;

const Section = styled.div`
  padding-top: 7px;
  padding-bottom: 10px;

  &:not(:last-child) {
    border-bottom: ${whiteBorder};
  }
`;

const SectionItem = styled.div`
  position: relative;
  padding-left: 52px;
  padding-right: 5px;
  color: #b1b0b0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  
  &:before {
    color: #FFFFFF;
    content: ${pr => pr.selected ? "'✓'" : 'none'};
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
  }

  &:hover {
    color: #fff;
  }
`;

const Settings = ({
                      closeControl, isScreenSharing,
                      zmClient, isUseVirtualBackground,
                      setIsBackgroundSelectionOpen
}) => {
    const { mediaContext: {mediaStream} } = useVideoContext();

    const [activeMicrophone, setActiveMicrophone] = useState(null);
    const [activeSpeaker, setActiveSpeaker] = useState(null);
    const [activeCamera, setActiveCamera] = useState(null);
    const [micList, setMicList] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);
    const [cameraList, setCameraList] = useState([]);
    
    const onDeviceChange = useCallback(() => {
        if (mediaStream) {
            setMicList(mediaStream.getMicList());
            setSpeakerList(mediaStream.getSpeakerList());
            setActiveMicrophone(mediaStream.getActiveMicrophone());
            setActiveSpeaker(mediaStream.getActiveSpeaker());

            const activeCamera = mediaStream?.getActiveCamera();

            if (!isAndroidOrIOSBrowser()) {
                setCameraList(mediaStream.getCameraList());
                setActiveCamera(activeCamera);
            }

            /*
                Bug in Zoom with detecting activeCamera on Mobile
                Mobile devices works only with MobileVideoFacingMode
            */
            if (isAndroidOrIOSBrowser() && (activeCamera === MobileVideoFacingMode.User || activeCamera === MobileVideoFacingMode.Environment)) {
                setActiveCamera(activeCamera);
            }
        }
    }, [mediaStream]);

    useEffect(() => {
        if (zmClient) {
            zmClient.on('device-change', onDeviceChange);

            return () => {
                zmClient.off('device-change', onDeviceChange);
            };
        }
    }, [zmClient, onDeviceChange]);

    useMount(() => {
        setActiveMicrophone(mediaStream?.getActiveMicrophone());
        setActiveSpeaker(mediaStream?.getActiveSpeaker());
        setActiveCamera(mediaStream?.getActiveCamera());

        if (isAndroidOrIOSBrowser()) {
            setCameraList([
                { deviceId: MobileVideoFacingMode.User, label: "Front-facing" },
                { deviceId: MobileVideoFacingMode.Environment, label: "Rear-facing" }
            ]);
        } else {
            setCameraList(mediaStream?.getCameraList() ?? [])
        }

        setMicList(mediaStream?.getMicList() ?? []);
        setSpeakerList(mediaStream?.getSpeakerList() ?? []);
    });

    const onReplaceVideoTrack = async (deviceId) => {
        deviceIdStorage.setVideoDeviceId(deviceId);
        if (activeCamera !== deviceId) {
            await mediaStream?.switchCamera(deviceId);
            setActiveCamera(deviceId);
        } else {
            closeControl();
        }
    };

    const onReplaceAudioTrack = async (deviceId) => {
        deviceIdStorage.setAudioDeviceId(deviceId);
        if (deviceId !== activeMicrophone) {
            await mediaStream?.switchMicrophone(deviceId);
            setActiveMicrophone(mediaStream?.getActiveMicrophone());
        } else {
            closeControl();
        }
    };

    const onReplaceSpeaker = async (deviceId) => {
        deviceIdStorage.setAudioOutputDeviceId(deviceId);
        if (deviceId !== activeSpeaker) {
            await mediaStream?.switchSpeaker(deviceId);
            setActiveSpeaker(mediaStream?.getActiveSpeaker());
        } else {
            closeControl();
        }
    };

    const onScreenShareClick = useCallback(async () => {
        if (isScreenSharing) return;

        const selfShareCanvas = document.getElementById(mediaStream.isStartShareScreenWithVideoElement() ? SELF_SHARE_VIDEO : SELF_SHARE_CANVAS);
        if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
            await mediaStream?.startShareScreen(selfShareCanvas, {
                optimizedForSharedVideo: true,
            });
        }
        closeControl();
    }, [mediaStream, isScreenSharing]);

    return (
        <Wrapper>
            <Header>
                Settings
                <FontAwesomeIcon icon={faTimes} onClick={closeControl} />
            </Header>

            {!isMobile && (
                <Section>
                    <MenuItem
                        onClick={onScreenShareClick}
                        isDisabled={isScreenSharing}
                    >
                        <FontAwesomeIcon icon={faTv} color={"white"}/>
                        &nbsp;&nbsp;Share screen
                    </MenuItem>
                </Section>
            )}
            {!!(isSupportedVB && mediaStream?.isSupportVirtualBackground() && isUseVirtualBackground) && (
                <MenuItem onClick={() => {
                    setIsBackgroundSelectionOpen(true);
                    closeControl();
                }}>
                    <FontAwesomeIcon icon={faImage} color={'white'} />
                    &nbsp;&nbsp;Backgrounds
                </MenuItem>
            )}
            
            <Section>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faVideo} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Webcam
                </SectionTitle>
                {cameraList && cameraList.length ? cameraList.map((device) => (
                    <SectionItem
                        selected={device.deviceId === activeCamera}
                        key={device.deviceId}
                        onClick={async () => {
                            if (device.deviceId !== activeCamera) {
                                await onReplaceVideoTrack(device.deviceId)
                            } else {
                                closeControl();
                            }
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
                <br/>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faMicrophone} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Microphone
                </SectionTitle>
                {micList && micList.length ? getUnique(micList, "deviceId").map((device) => (
                    <SectionItem
                        selected={device.deviceId === activeMicrophone}
                        key={device.deviceId}
                        onClick={async () => {
                            if (device.deviceId !== activeMicrophone) {
                                await onReplaceAudioTrack(device.deviceId)
                            } else {
                                closeControl();
                            }
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
                <br/>
                <SectionTitle isGray={true}>
                    <FontAwesomeIcon icon={faVolumeUp} color={"#6e6d6d"} />
                    &nbsp;&nbsp;Speakers
                </SectionTitle>

                {speakerList && speakerList.length ? getUnique(speakerList, "deviceId").map((device) => (
                    <SectionItem
                        selected={device.deviceId === activeSpeaker}
                        key={device.deviceId}
                        onClick={async () => {
                            await onReplaceSpeaker(device.deviceId);
                        }}
                    >
                        {device.label || 'Unnamed device'}
                    </SectionItem>
                )) : (
                    <SectionItem selected={true}>
                        Unnamed device
                    </SectionItem>
                )}
            </Section>
        </Wrapper>
    );
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
    isUseVirtualBackground: store.system.virtualBackground,
    currentUser: store.users.currentUser,
});

const mapDispatchToProps = {
    setIsBackgroundSelectionOpen
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
