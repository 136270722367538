import { io } from "socket.io-client";
import store from "store";
import { needSocketReconnectAction, setErrorInfoBlockVisibleAction } from "../store/controller/actions";
import { getSyncDateNow } from "../utils/helpers";
import { sendZoomStatistics } from "../store/zoom/actions";

const socketModule = () => {
    const {pathname} = document.location;
    // Returns unconnected sockets for the registration flow:
    if (pathname.indexOf('/event/') !== -1) return {
        socket: {
            on:()=>{},
            emit:()=>{},
            off:()=>{},
        }
    };

    const isLocalhost = window.location.hostname === 'localhost';
    const protocol = window.location.protocol;
    const host = isLocalhost ? window.location.hostname : window.location.host;
    const port = isLocalhost ? 3000 : 443;
    const url = process.env.REACT_APP_LOCAL_SOCKET_URL ?
        process.env.REACT_APP_LOCAL_SOCKET_URL :
        `${protocol}//${host}:${port}`;

    const socket = io(url, {
        secure: !isLocalhost,
        transports: ['websocket', 'polling', 'flashsocket']
    });

    socket.on('connect', () => {
        console.info('Connected to server via socket.');
        const {
            controller: { currentEvent: { eventId }, currentSession: { roomNumber }, needSocketReconnect },
            users: { currentUser: {userId} }
        } = store.getState();


        if (needSocketReconnect) {
            const needStoreToRedis = ['/profile', '/matches', '/room', '/live-event']
                .findIndex((el) => pathname.indexOf(el) !== -1) !== -1;

            const isDirectory = pathname.includes('directory');
            socket.emit('system.socket_reconnect', {
                eventId,
                userId,
                roomNumber,
                needStoreToRedis,
                isDirectory
            });
            store.dispatch(needSocketReconnectAction(false));

            const {eventSessions, currentSessionNumber} = store.getState().checkin;
            const {showRightFlyInPopup, screenView, errorInfoBlockReasons} = store.getState().controller;
            const session = eventSessions.find(s => currentSessionNumber === 0 ? s.number === 1 : s.number === currentSessionNumber);

            if (errorInfoBlockReasons && errorInfoBlockReasons.networkError) {
                store.dispatch(sendZoomStatistics(
                    {
                        reason: 'networkDisconnected',
                        method: "FE showErrorInfoBlock",
                    }, 'Message'
                ));
            }

            if (session && screenView === 'video' && !showRightFlyInPopup) {
                const time = session.end - getSyncDateNow();
                if (time < (40 * 1000)) {
                    window.location.reload();
                }
            }

            setTimeout(()=>{
                const {controller: {screenView, currentSession: {didConnected}}} = store.getState();
                store.dispatch(setErrorInfoBlockVisibleAction(false));
                if (didConnected === 0 && screenView === 'video') window.location.reload();
            }, 3000);
        }
    });

    socket.on('disconnect', (reason) => {
        console.info(`Socket was disconnected because ${reason}`);
        store.dispatch(needSocketReconnectAction(true));
        store.dispatch(setErrorInfoBlockVisibleAction(true, {networkError: true}));
    });

    return {
        socket,
    }
};

export default socketModule()
