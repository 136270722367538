import { useCallback, useEffect, useRef, useState } from "react";

import store from "store";
import { sendZoomStatistics } from "store/zoom/actions";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useMount, useUnmount } from "../useUnmount/useUnmount";


const mult = "\u00D7";
const AudioMetrics = [
    {
        title: "Frequency",
        value: "sample_rate",
        format: (value) => `${value} khz`
    },
    {
        title: "Latency",
        value: "rtt",
        format: (value) => `${value} ms`
    },
    {
        title: "Jitter",
        value: "jitter",
        format: (value) => `${value} ms`
    },
    {
        title: "Packet Loss - Avg(Max)",
        value: ["avg_loss", "max_loss"],
        format: (value) => {
            const nv = value.map((s) => {
                return `${((Number(s) / 1000) * 100).toFixed(1)}%`;
            });
            const [avg, max] = nv;
            return `${avg} (${max})`;
        }
    }
];
const VideoMetrics = [
    {
        title: "Latency",
        value: "rtt",
        format: (value) => `${value} ms`
    },
    {
        title: "Jitter",
        value: "jitter",
        format: (value) => `${value} ms`
    },
    {
        title: "Packet Loss - Avg(Max)",
        value: ["avg_loss", "max_loss"],
        format: (value) => {
            const nv = value.map((s) => {
                return `${((Number(s) / 1000) * 100).toFixed(1)}%`;
            });
            const [avg, max] = nv;
            return `${avg} (${max})`;
        }
    },
    {
        title: "Resolution",
        value: ["width", "height"],
        format: (value) => {
            const [w, h] = value;
            if (w === 0 && h === 0) {
                return "-";
            }
            return `${w}${mult}${h}`;
        }
    },
    {
        title: "Frame Per Second	",
        value: "fps",
        format: (value) => `${value} fps`
    }
];
const AudioQosDataShape = {
    avg_loss: 0,
    jitter: 0,
    max_loss: 0,
    rtt: 0,
    sample_rate: 0
};

const VideoQosDataShape = {
    avg_loss: 0,
    fps: 0,
    height: 0,
    jitter: 0,
    max_loss: 0,
    rtt: 0,
    width: 0,
    sample_rate: 0
};
const getDataSouce = (
    streamMertics,
    encodingData,
    decodingData
) => {
    return streamMertics.map((metrics, index) => {
        let send = "";
        let receive = "";
        if (encodingData) {
            let value;
            if (Array.isArray(metrics.value)) {
                value = metrics.value.map((m) => (encodingData)[m]);
            } else {
                value = (encodingData)[metrics.value];
            }
            send = value === 0 ? "-" : metrics.format(value);
        }
        if (decodingData) {
            let value;
            if (Array.isArray(metrics.value)) {
                value = metrics.value.map((m) => (decodingData)[m]);
            } else {
                value = (decodingData)[metrics.value];
            }
            receive = value === 0 ? "-" : metrics.format(value);
        }
        return {
            name: metrics.title,
            send,
            receive,
            key: index
        };
    });
};

const useAudioVideoStatistic = ({ isStartedAudio, isStartedVideo, isMuted }) => {
    const { mediaContext: { mediaStream } } = useVideoContext();
    const {zoom: {zmClient}} = store.getState();
    const [audioEncodingStatistic, setAudioEncodingStatistic] = useState();
    const [audioDecodingStatistic, setAudioDecodingStatistic] = useState();
    const [videoEncodingStatistic, setVideoEncodingStatistic] = useState();
    const [videoDecodingStatistic, setVideoDecodingStatistic] = useState();
    const [shareEncodingStatistic, setShareEncodingStatistic] = useState();
    const [shareDecodingStatistic, setShareDecodingStatistic] = useState();
    const videoDecodeTimerRef = useRef(0);
    const audioDecodeTimerRef = useRef(0);
    const sendTimerRef = useRef(0);
    const sendDataRef = useRef(null);

    const clearSendTimer = () => {
        if (sendTimerRef.current) {
            clearInterval(sendTimerRef.current);
            sendTimerRef.current = 0;
        }
    };

    const clearAudioTimer = () => {
        if (audioDecodeTimerRef.current) {
            clearTimeout(audioDecodeTimerRef.current);
            audioDecodeTimerRef.current = 0;
        }
    };

    const clearVideoTimer = () => {
        if (videoDecodeTimerRef.current) {
            clearTimeout(videoDecodeTimerRef.current);
            videoDecodeTimerRef.current = 0;
        }
    };

    const onAudioStatisticChange = useCallback((payload) => {
        const {
            data: { encoding, ...restProps }
        } = payload;
        if (encoding) {
            setAudioEncodingStatistic({ ...restProps });
        } else {
            setAudioDecodingStatistic({ ...restProps });
            clearAudioTimer();
            // Reset audio decode data if no data come in over 2 seconds
            audioDecodeTimerRef.current = window.setTimeout(() => {
                setAudioDecodingStatistic(AudioQosDataShape);
            }, 2000);
        }
    }, []);

    const onVideoStatisticChange = useCallback((payload) => {
        const {
            data: { encoding, ...restProps }
        } = payload;
        if (encoding) {
            setVideoEncodingStatistic({ ...restProps });
        } else {
            setVideoDecodingStatistic({ ...restProps });
            clearVideoTimer();
            // Reset video decode data if no data come in over 2 seconds
            videoDecodeTimerRef.current = window.setTimeout(() => {
                setVideoDecodingStatistic(VideoQosDataShape);
            }, 2000);
        }
    }, []);

    const onShareStatisticChange = useCallback((payload) => {
        const {
            data: { encoding, ...restProps }
        } = payload;
        if (encoding) {
            setShareEncodingStatistic({ ...restProps });
        } else {
            setShareDecodingStatistic({ ...restProps });
            clearVideoTimer();
            // Reset video decode data if no data come in over 2 seconds
            videoDecodeTimerRef.current = window.setTimeout(() => {
                setShareDecodingStatistic(VideoQosDataShape);
            }, 2000);
        }
    }, []);

    const audioDataSource = getDataSouce(AudioMetrics, audioEncodingStatistic, audioDecodingStatistic);
    const videoDataSource = getDataSouce(VideoMetrics, videoEncodingStatistic, videoDecodingStatistic);
    const shareDataSource = getDataSouce(VideoMetrics, shareEncodingStatistic, shareDecodingStatistic);

    useEffect(() => {
        sendDataRef.current = {
            isStartedAudio, isStartedVideo, isMuted,
            audioDataSource, videoDataSource, shareDataSource
        };
    }, [
        audioDataSource, videoDataSource, shareDataSource,
        isStartedAudio, isStartedVideo, isMuted
    ]);

    useEffect(() => {
        if (zmClient) {
            zmClient.on("audio-statistic-data-change", onAudioStatisticChange);
            zmClient.on("video-statistic-data-change", onVideoStatisticChange);
            zmClient.on("share-statistic-data-change", onShareStatisticChange);

            return () => {
                zmClient.off("audio-statistic-data-change", onAudioStatisticChange);
                zmClient.off("video-statistic-data-change", onVideoStatisticChange);
                zmClient.off("share-statistic-data-change", onShareStatisticChange);
            };
        }
    }, [zmClient, onAudioStatisticChange, onVideoStatisticChange, onShareStatisticChange]);

    useEffect(() => {
        if (!isStartedAudio || isMuted) {
            setAudioEncodingStatistic(AudioQosDataShape);
        }
    }, [isStartedAudio, isMuted]);

    useEffect(() => {
        if (!isStartedVideo) {
            setVideoEncodingStatistic(VideoQosDataShape);
        }
    }, [isStartedVideo]);

    useMount(() => {
        if (mediaStream) {
            const { encode: audioEncoding, decode: audioDecoding } = mediaStream.getAudioStatisticData();
            const { encode: videoEncoding, decode: videoDecoding } = mediaStream.getVideoStatisticData();
            setAudioDecodingStatistic(audioDecoding);
            setAudioEncodingStatistic(audioEncoding);
            setVideoDecodingStatistic(videoDecoding);
            setVideoEncodingStatistic(videoEncoding);

            sendTimerRef.current = setInterval(() => {
                if (sendDataRef && sendDataRef.current) store.dispatch(sendZoomStatistics(sendDataRef.current, "live-statistics"));
            }, 60000);
        }
    });

    useUnmount(() => {
        clearAudioTimer();
        clearVideoTimer();
        clearSendTimer();
    });
};

export default useAudioVideoStatistic;
