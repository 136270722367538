import _ from "lodash";
import config from "../../utils/config";
import {
    getInstanceAxios,
    prepareEventData,
    prepareEventRegistrationData,
    prepareUserData,
    stopStream
} from "../../utils/helpers";
import { setCurrentUserAction } from "../users/actions";
import { setAllDataProvidedAction, setCurrentEventAction } from "../controller/actions";
import { setTestMicOnlyModeAction, setTestPermitMobileAction } from "../test/actions";
import types from "../constans";
import socketModule from "../../utils/socketModule";
import { setSystemToken } from "../../utils/AuthManager";

export const storeUserToRedisAction = (eventId, userId, isDirectory) => (dispatch) => {
    if (!eventId || !userId) return;

    console.warn("STORE USER TO REDIS!!!", {isDirectory});
    socketModule.socket.emit('store-to-redis', {
        userId,
        eventId,
        isDirectory
    });
    socketModule.socket.emit('connect_to_self_room', {
        eventId,
        userId
    });
    dispatch(setUserOnlineAction());
};

export const setUserLoggedAction = (flag) => ({type: types.SET_USER_LOGGED_IN_ACTION, flag});
export const setCheckinStepsAction = (steps) => ({type: types.SET_CHECKIN_STEPS, steps});
export const setCheckinOptionsAction = (options) => ({type: types.SET_CHECKIN_OPTIONS, options});
export const setCheckinReadyAction = (isReady) => ({type: types.SET_CHECK_IN_READY, isReady});
export const setUserOnlineAction = () => ({type: types.SET_USER_ONLINE});
export const setEventRegistrationInfoAction = (registration) => ({type: types.SET_EVENT_REGISTRATION_INFO, registration});
export const setEventRegistrationsAction = (registrations) => ({type: types.SET_EVENT_REGISTRATIONS, registrations});
export const setCurrentSessionNumberAction = (sessionNumber) => ({type: types.SET_CURRENT_SESSION_NUMBER, sessionNumber});
export const setCheckinFishbowlPopupAction = (visible) => ({type: types.SET_CHECKIN_FISHBOWL_POPUP, visible});
export const setCheckinFishbowlMiniPopupAction = (visible) => ({type: types.SET_CHECKIN_FISHBOWL_MINI_POPUP, visible});
export const setCheckinFishbowlMiniSearchModeAction = (flag) => ({type: types.SET_FISHBOWL_MINI_SEARCH_MODE, flag});
export const setTargetJoinPopupAction = (visible) => ({type: types.SET_TARGET_JOIN_POPUP, visible});
export const setTargetJoinMiniPopupAction = (visible) => ({type: types.SET_TARGET_JOIN_MINI_POPUP, visible});
export const setAvailableToMatchAction = (available) => ({type: types.SET_AVAILABLE_TO_MATCH, available});
export const setDetectRtcDataAction = (data) => ({type: types.SET_DETECT_RTC_DATA, data});

export const hideCheckinFishbowlPopupAction = () => (dispatch) => {
    dispatch({ type: types.HIDE_CHECKIN_FISHBOWL_POPUP });
    setTimeout(() => {
        dispatch(setCheckinFishbowlPopupAction(false))
    }, 500);
};
export const hideTargetJoinPopupAction = (showMini = false) => (dispatch) => {
    dispatch({ type: types.HIDE_TARGET_JOIN_POPUP });
    setTimeout(() => {
        dispatch(setTargetJoinPopupAction(false));
        if (showMini) dispatch(setTargetJoinMiniPopupAction(true));
    }, 500);
};

export const setCurrentEventSessionAction = (eventSessions) => {

    const sessions = eventSessions.map(session => ({
        number: parseInt(session.session_number),
        start: Date.parse(session.session_start_time),
        end: Date.parse(session.session_end_time),
    }));

    return {
        type: types.SET_CURRENT_EVENT_SESSIONS,
        sessions: sessions
    }
};

export const getCurrentEventSessionAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent }, checkin: { eventSessions } } = getState();
    if (!eventSessions.length) {
        const eventResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/events/${currentEvent.eventId}?withSessions=true`);
        if (eventResponse && eventResponse.data.status === 200) {
            dispatch(setCurrentEventSessionAction(eventResponse.data.eventSessions));
        }
    }
}

export const updateSessionAvailabilityAfterWrapup = (eventId) => async (dispatch, getState) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/update-session-availability-after-wrapup`, { eventId });
    const status = _.get(response, 'data.status', null);
    const message = _.get(response, 'data.message', null);

    if (status === 200 && message === 'Success') {
        const { checkin: { eventRegistration } } = getState();
        dispatch(setEventRegistrationInfoAction({
            ...eventRegistration,
            availableForMatchingSessionNum: 0,
            isDisabledMatchingAfterWrapup: false
        }));
    }
};

export const handleCheckInInfo = (eventId, userId, flow) => async (dispatch, getState) => {
    const { controller: { currentEvent }, users: { currentUser }, checkin: { eventRegistration, eventRegistrations, eventSessions } } = getState();
    const previouslyMetUsersIdsResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/event-registrations/get-previously-met-users-ids`);
    if (previouslyMetUsersIdsResponse && previouslyMetUsersIdsResponse.data && previouslyMetUsersIdsResponse.data.status === 200) {
        dispatch(setPreviouslyMetUsersIds(previouslyMetUsersIdsResponse.data.previouslyMetUsersIds));
    }

    if (currentEvent.eventId && currentEvent.accountId && currentUser.userId && eventRegistration && eventRegistration.eventId) {
        dispatch(setCheckinReadyAction(true));
        dispatch(setAllDataProvidedAction(true));
        socketModule.socket.emit('checkin.connect_to_channel', {eventId});
        return;
    }
    let error = false;
    socketModule.socket.emit('checkin.connect_to_channel', {eventId});
    if (!currentEvent.eventId || !currentEvent.accountId || !eventSessions.length) {
        const eventResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/events/${eventId}?withSessions=true`);
        if (eventResponse && eventResponse.data.status === 200) {
            dispatch(setCurrentEventAction(prepareEventData(eventResponse.data.eventData, eventResponse.data.accountData)));
            dispatch(setCurrentEventSessionAction(eventResponse.data.eventSessions));
            dispatch(setTestMicOnlyModeAction(!!eventResponse.data.eventData.mic_only));
            dispatch(setTestPermitMobileAction(!!eventResponse.data.eventData.permit_mobile));
            dispatch(setOrganizerVideoAction(eventResponse.data.eventData.kickoff_video_url));
        } else {
            error = true
        }
    }

    if (!currentUser.userId) {
        const userResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/users/${userId}?event_id=${eventId}`, {
            crossDomain: true
        });
        if (userResponse && userResponse.data.status === 200) {
            const userData = prepareUserData(userResponse.data.userData);
            dispatch(setCurrentUserAction(userData));
        } else {
            error = true
        }
    }

    if ((!Object.keys(eventRegistration).length || !eventRegistrations.length) && eventId && userId) {
        const eventRegistrationResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/event-registrations/${eventId}/${userId}`, {
            crossDomain: true
        });
        if (eventRegistrationResponse && eventRegistrationResponse.data.status === 200) {
            dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(eventRegistrationResponse.data.eventRegistration || {})));
            dispatch(setEventRegistrationsAction(eventRegistrationResponse.data.eventRegistrations));
        } else {
            error = true
        }
    }
    if (!error) {
        dispatch(setCheckinReadyAction(true));
        dispatch(setAllDataProvidedAction(true));
    }
};

export const fetchEventRegistrationFullInfoAction = () => async (dispatch, getState) => {
    const { controller: { currentEvent: {eventId} }, users: { currentUser: {userId} }, checkin: { eventRegistration, eventRegistrations } } = getState();
    if ((!Object.keys(eventRegistration).length || !eventRegistrations.length) && eventId && userId) {
        const eventRegistrationResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/event-registrations/${eventId}/${userId}`, {
            crossDomain: true
        });
        if (eventRegistrationResponse && eventRegistrationResponse.data.status === 200) {
            dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(eventRegistrationResponse.data.eventRegistration || {})));
            dispatch(setEventRegistrationsAction(eventRegistrationResponse.data.eventRegistrations));
        }
    }
};

export const fetchEventRegistrationsAction = (forceUpdate) => async (dispatch, getState) => {
    const { controller: { currentEvent: {eventId} }, users: { currentUser: {userId} }, checkin: { eventRegistration, eventRegistrations } } = getState();
    if ((!Object.keys(eventRegistration).length || !eventRegistrations.length || forceUpdate) && eventId && userId) {
        const eventRegistrationResponse = await getInstanceAxios().get(`${config.NODE_API_URL}/event-registrations/${eventId}/${userId}`, {
            crossDomain: true
        });
        if (eventRegistrationResponse && eventRegistrationResponse.data.status === 200) {
            dispatch(setEventRegistrationsAction(eventRegistrationResponse.data.eventRegistrations));
        }
    }
};

export const updateUserProfileInfo = (data) => async (dispatch, getState) => {
    const { users: { currentUser } } = getState();
    const { profile, eventId, eventTags } = data;
    dispatch(setCurrentUserAction({ ...currentUser, ...profile }));

    await getInstanceAxios().post(`${config.NODE_API_URL}/users/update-user-profile`, {
        user_id: profile.userId,
        first_name: profile.firstName,
        last_name: profile.lastName,
        company: profile.company,
        title: profile.title,
        profile_photo: profile.profilePhoto,
        photo_name: profile.photoName,
        profile_color_hex: profile.profileColorHex,
        bio: profile.bio,
        age: profile.age,
        gender: profile.gender,
        location: profile.location,
        event_id: eventId,
        event_content: profile.content,
        event_tags: eventTags,
        twitter_link: profile.twitterLink,
        linkedin_link: profile.linkedinLink,
        from_profile: true
    });
};

export const storeMeetTagsAction = (tags) => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } }, checkin: {eventRegistration} } = getState();
    dispatch(setEventRegistrationInfoAction({ ...eventRegistration, meetTags: tags }));
    await getInstanceAxios().post(`${config.NODE_API_URL}/users/${userId}/${eventId}`, {
        meetTags: tags,
    }, {
        crossDomain: true
    });
};

export const setMeetChoiceAction = (choice, otherUserId) => async (dispatch, getState) => {
    const { controller: { currentEvent: { eventId } }, users: { currentUser: { userId } }, checkin: {eventRegistration} } = getState();
    const updateResponse = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-meet-users`, {
        eventId,
        userId,
        choice,
        otherUserId
    }, {
        crossDomain: true
    });
    if (updateResponse && updateResponse.data.status === 200) {
        dispatch(setEventRegistrationInfoAction({ ...eventRegistration, userUserPreferences: updateResponse.data.registration.user_user_preferences }));
    }
};

export const storeStepTimestamp = (slug) => async (dispatch, getState) => {
    const {controller: { currentEvent: { eventId } }, users: { currentUser: { userId } } } = getState();
    const localTime = Date.now();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-step-timestamp/${eventId}/${userId}`,
        {
            slug, localTime, timezone
        }, {crossDomain: true});
};

export const updateCurrentStep = (currentStep) => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId } },
        users: { currentUser: { userId } },
        checkin: { eventRegistration } } = getState();
    if (eventRegistration.currentStep > currentStep) {
        return true;
    }
    const eventRegistrationResponse = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/set-user-step/${eventId}/${userId}`,
        {currentStep}, {crossDomain: true});
    if (eventRegistrationResponse && eventRegistrationResponse.data.status === 200) {
        dispatch({type: types.UPDATE_CHECK_IN_CURRENT_STEP, currentStep});
        return true;
    }
    return false;
};

export const checkUser = ({eventId}, initial = false, isDirectory = false) => async (dispatch) => {
    const localTime = Date.now();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/auth/${eventId}`, {localTime, timezone, initial, isDirectory}, {crossDomain: true});

    if (response && response.data.status === 200) {
        dispatch(setCurrentUserAction(prepareUserData(response.data.user)));
        dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(response.data.eventRegistration)));
        return {
            loggedIn: true,
            hasRegistration: true
        };
    } else if (response && response.data.status === 401) {
        dispatch(setCurrentUserAction(prepareUserData(response.data.user)));
        return {
            loggedIn: true,
            hasRegistration: false
        };
    }

    return {
        loggedIn: false,
        hasRegistration: false
    };
};

export const checkAdminUser = ({token}) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/admin-auth/`, {token}, {crossDomain: true});

    if (response && response.data.status === 200) {
        dispatch(setCurrentUserAction(prepareUserData(response.data.user)));
        return true;
    }

    return false;
};

export const createRawEventRegistrationAction = ({userId, eventId, eventTags = null, ticketId = null, source = null}) => async (dispatch, getState) => {
    if (!userId || !eventId) {
        console.warn('createRawEventRegistrationAction: Required data is missing!');
        return null;
    }

    const {registration: { createRegistrationOnFly }} = getState();
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/create-raw-registration`, {
        userId, eventId, eventTags, onFly: createRegistrationOnFly, ticketId, source
    }, {crossDomain: true});
    return response.data;
};

export const checkinCreateRawUserAction = (email, firstName, lastName) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/create-raw-user`, {email, firstName, lastName}, {crossDomain: true});
    return response.data;
};

export const checkUserExistAction = (email) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/check-user-exist`,
        {email}, {crossDomain: true});
    return response.data;
};

export const createUserAndEventRegistrationOnFlyAction = (eventId, email, firstName, lastName, password, preferred_language, userTimezone) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/create-user-registration-fly`
        , {eventId, email, firstName, lastName, password, preferred_language, user_timezone: userTimezone}, {crossDomain: true});

    if (response && response.data.status === 'success') {
        setSystemToken('jwt_token', response.data.jwt_token);
        if (response.data.user) setSystemToken('user_id', response.data.user.user);
        dispatch(setCurrentUserAction(prepareUserData(response.data.user)));
        dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(response.data.registration || {})));
        dispatch(setUserLoggedAction(true));
        dispatch(setCheckinReadyAction(true));

    }
    return response.data;
};

export const checkinLoginAction = (eventId, email, password, preferred_language) => async (dispatch) => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/auth-via-credentials`
        , {eventId, email, password, preferred_language}, {crossDomain: true});

    if (response && response.data.status === 200) {
        if (response.data.jwt_token) setSystemToken('jwt_token', response.data.jwt_token);
        if (response.data.limited_token) setSystemToken('limited_token', response.data.limited_token);

        if (response.data.user) {
            setSystemToken('user_id', response.data.user.user);
            response.data.user = prepareUserData(response.data.user);
            dispatch(setCurrentUserAction(response.data.user));
            if (response.data.registration) {
                dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(response.data.registration || {})));
                dispatch(setUserLoggedAction(true));
                dispatch(setCheckinReadyAction(true));
            }
        }
    }
    return response.data;
};

export const checkEventRegistration = (eventId, email) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/check-registration/${eventId}`, {email}, {crossDomain: true});
    return response.data;
};

export const createFishbowlFromCheckinAction = ({startEarly = true, mini = false}) => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId } },
        users: { currentUser: { userId } },
        checkin: { currentSessionNumber }
    } = getState();

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/fishbowl/create-from-checkin`, {
        start_early: startEarly,
        event_id: eventId,
        session_number: currentSessionNumber,
        user_id: userId
    }, {crossDomain: true});

    if (response && response.data.status === 200) {
        if (startEarly && !response.data.userSession) {
            dispatch(setTargetJoinPopupAction(false));
            dispatch(mini ? setCheckinFishbowlMiniPopupAction(true) : setCheckinFishbowlPopupAction(true));
        } else {
            dispatch(setCheckinFishbowlMiniSearchModeAction(true));
            dispatch(setCheckinFishbowlMiniPopupAction(true));
            return {matching: true};
        }
    }
};

export const setFishbowlFunctionalityBlocked = () => (dispatch, getState) => {
    const { checkin: { showCheckinFishbowlPopup } } = getState();
    if (showCheckinFishbowlPopup) dispatch(hideCheckinFishbowlPopupAction());
    dispatch ({ type: types.SET_FISHBOWL_FUNCTIONALITY_BLOCKED });
};

export const setNeedShowGreenScreenAction = (flag) => ({type: types.SET_NEED_SHOW_GREEN_SCREEN, flag});

export const handleNextStepAction = (history, path, nextStepIndex = null) => (dispatch, getState) => {
    const { checkin: {steps, currentStream} } = getState();
    const currentStepIndex = steps.findIndex(step => window.location.pathname.indexOf(step.slug) !== -1);
    const currentStep = steps[currentStepIndex];
    const nextStep = steps[nextStepIndex || currentStepIndex+1];

    const getPageId = (slug) => `page_${slug}`;

    if (currentStep) {
        const currentStepClasses = document.getElementById(getPageId(currentStep.slug)).className;
        document.getElementById(getPageId(currentStep.slug)).className += ' animate__animated animate__fadeOutLeft animate__fast';
        setTimeout(() => {
            if(document.getElementById(getPageId(currentStep.slug))) document.getElementById(getPageId(currentStep.slug)).className = currentStepClasses;
        }, 500);
    }

    if (nextStep) {
        const nextStepClasses = document.getElementById(getPageId(nextStep.slug)).className;
        document.getElementById(getPageId(nextStep.slug)).className += ` animate__animated animate__fadeInRight animate__fast`;
        setTimeout(() => {
            document.getElementById(getPageId(nextStep.slug)).className = nextStepClasses;
            if (history) {
                history.push(path);
                stopStream(currentStream);
            }
        }, 500);
    }
};
export const handlePrevStepAction = (history, path, prevStepIndex = null) => (dispatch, getState) => {
    const { checkin: {steps} } = getState();
    const currentStepIndex = steps.findIndex(step => window.location.pathname.indexOf(step.slug) !== -1);
    const currentStep = steps[currentStepIndex];
    const prevStep = steps[prevStepIndex !== null ? prevStepIndex : currentStepIndex-1];
    const getPageId = (slug) => `page_${slug}`;

    if (currentStep) {
        const currentStepClasses = document.getElementById(getPageId(currentStep.slug)).className;
        document.getElementById(getPageId(currentStep.slug)).className += ' animate__animated animate__fadeOutRight animate__fast';
        setTimeout(() => {
            document.getElementById(getPageId(currentStep.slug)).className = currentStepClasses;
        }, 500);
    }

    if (prevStep) {
        const prevStepClasses = document.getElementById(getPageId(prevStep.slug)).className;
        document.getElementById(getPageId(prevStep.slug)).className += ' animate__animated animate__fadeInLeft animate__fast';
        setTimeout(() => {
            document.getElementById(getPageId(prevStep.slug)).className = prevStepClasses;
            if (history) history.push(path);
        }, 500);
    }
};

export const setErCheckedInAction = () => async (dispatch, getState) => {
    const {checkin: {eventRegistration: {eventId, userId, checkedIn}, eventRegistrations}} = getState();

    if (!eventId || !userId || checkedIn) return;

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/event-registrations/set-checked-in`, {
        eventId,
        userId,
    }, {
        crossDomain: true
    });

    if (response && response.data.status === 200 && response.data.eventRegistration) {
        const registration = response.data.eventRegistration;
        dispatch(setEventRegistrationInfoAction(prepareEventRegistrationData(registration)));
        dispatch(setEventRegistrationsAction(eventRegistrations.map(er => er.id === registration.id ? registration : er)));
    }
    return true;
};


export const setOrganizerVideoAction = (source) => ({ type: types.SET_ORGANIZER_VIDEO, source });

export const setViewedIntroToMeetawayAction = () => async (dispatch, getState) => {
    const {users: { currentUser: { userId } }} = getState();
    await getInstanceAxios().put(`${config.NODE_API_URL}/users/set-viewed-intro-to-meetaway`, {
        userId,
    }, {
        crossDomain: true
    });
};

export const setPwdForUserWhoHaveNotPwdAction  = ({email, password}) => async () => {
    // for a user who doesn’t yet have a pwd in the videosystem
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/users/set-password`, {
        password, email
    }, {
        crossDomain: true
    });

    if (response && response.data.status === 200) {
        return response.data;
    }

    return false;
};

export const checkTeamAuth = ({eventId}) => async () => {
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/events/check-team-auth`, {
        eventId
    }, {
        crossDomain: true
    });

    if (response && response.data.status === 200) {
        return true;
    }
    return false;
};

export const updateEventRegistrationCheckInSourceAction = (eventId, userId, source) => async () => {
    const response = await getInstanceAxios().patch(`${config.NODE_API_URL}/event-registrations/update-source`, {
        event_id: eventId,
        user_id: userId,
        source
    }, {
        crossDomain: true
    });

    return response.data.status === 200;
};

export const updateEventRegistrationAction = (updatedEventRegistration) => async (dispatch) => {
    const response = await getInstanceAxios().put(`${config.NODE_API_URL}/event-registrations/event-registration`, updatedEventRegistration, {crossDomain: true});

    if (response && response.status === 200 && response.data.updatedEventRegistration) {
        dispatch({type: types.UPDATE_EVENT_REGISTRATION, updatedEventRegistration});
        return response;
    } else {
        console.error('updateEventRegistrationAction error response = ', response);
    }
};

export const updateEventRegistrationsEventTagsAction = (updatedEventRegistrations) => ({type: types.UPDATE_EVENT_REGISTRATIONS_EVENT_TAGS, updatedEventRegistrations});

export const setPreviouslyMetUsersIds = (ids) => ({ type: types.SET_PREVIOUS_MET_USERS_IDS, ids });

export const setOnlineUsersIds = (ids) => ({ type: types.SET_ONLINE_USERS_IDS, ids });

export const setKickoffIsOverAction = () => (dispatch) => {
    dispatch({ type: types.SET_KICKOFF_IS_OVER });
};

export const setKickoffIsHidden = (isHidden) => (dispatch) => {
    dispatch({ type: types.SET_KICKOFF_IS_HIDDEN, isHidden });
};

export const setLastMeetOver = () => (dispatch) => {
    dispatch({ type: types.SET_LAST_MEET_OVER });
};

export const setLastMeetPassed = () => (dispatch) => {
    dispatch({ type: types.SET_LAST_MEET_PASSED });
};
