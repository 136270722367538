import { useCallback, useEffect, useRef, useState } from "react";

export function useActiveUsers(zmClient) {
    const [activeUsers, setActiveUsers] = useState([]);
    const timerRef = useRef(null);

    const onActiveSpeakerChange = useCallback((payload) => {
        if (Array.isArray(payload) && payload.length > 0) {
            if (timerRef.current) clearTimeout(timerRef.current);
            setActiveUsers(payload);
            timerRef.current = setTimeout(() => {
                setActiveUsers([]);
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (zmClient) {
            zmClient.on("active-speaker", onActiveSpeakerChange);

            return () => {
                zmClient.off("active-speaker", onActiveSpeakerChange);
            };
        }
    }, [zmClient, onActiveSpeakerChange]);

    return activeUsers;
}
