import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

export const selectedParticipantContext = createContext(null);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(selectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant];
}

export function SelectedParticipantProvider({ children, zmClient }) {
  const { connectionStatus } = useVideoContext();
  const [selectedParticipant, _setSelectedParticipant] = useState(null);
  const lastClickTimeRef = useRef(0);

  const setSelectedParticipant = (participant) => {
    if ((Date.now() - lastClickTimeRef.current) < 1000) return;

    _setSelectedParticipant(participant);
    lastClickTimeRef.current = Date.now();
  };

  const handleParticipantDisconnected = useCallback(() => {
        const participants = zmClient?.getAllUser?.() ?? [];
        const isExist = participants.some(({userIdentity})=> userIdentity === selectedParticipant?.userIdentity)
        if (!isExist) {
          _setSelectedParticipant(null);
        }
  }, [zmClient, selectedParticipant]);

  useEffect(() => {
    if (zmClient) {
      zmClient.on('user-removed', handleParticipantDisconnected);

      return () => {
        zmClient.off('user-removed', handleParticipantDisconnected);
      };
    }
  }, [zmClient]);

  useEffect(() => {
    if (connectionStatus === 'closed') {
      _setSelectedParticipant(null);
    }
  }, [connectionStatus]);

  return (
    <selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </selectedParticipantContext.Provider>
  );
}
