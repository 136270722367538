import types from "../constans";
import config from "../../utils/config";
import { getInstanceAxios, getSyncDateNow } from "../../utils/helpers";
import socketModule from "../../utils/socketModule";
import fishbowlTypes from "../../components/Popups/FishbowlViewTypes";
import {
    clearExtendCallOptionsAction,
    hideNextPartnerBioPopupAction,
    logging,
    needShowRightFlyinAction,
    setDisconnectedAction,
    setErrorInfoBlockVisibleAction,
    setSelectedNextSessionVariantAction,
    showExtendCallPopupAction,
    switchFishbowlViewAction,
    userExtendCallAction
} from "../controller/actions";

export const SESSION_CONNECTED = 'SESSION_CONNECTED';

export const clearRoomInitialParams = () => ({type: types.CLEAR_ROOM_INITIAL_PARAMS});

export const sessionConnected = () => ({ type: SESSION_CONNECTED });

export const setCallRecordedAction = (needRecord) => {
    return {
        type: types.SET_CALL_RECORDED,
        needRecord: needRecord
    }
};

export const setPublisherIsReadyAction = (flag) => ({ type: types.SET_PUBLISHER_IS_READY, flag })

export const joinIsClickedAction = () => async (dispatch, getState) => {
    dispatch(setEndCallClickedAction(false));
    const {
        controller: { currentSession: { roomNumber, wasDisconnected, sessionStartTime }, currentEvent: { eventId } },
        users: { currentUser: { userId } },
    } = getState();

    console.warn('ROOM NUMBER: ', roomNumber ? roomNumber : 'Direct mode - no room number!');

    if (roomNumber !== '' && wasDisconnected === true) {
        socketModule.socket.emit('fishbowl.publisher_restore_call', { eventId, roomNumber, userId });
    }

    dispatch(logging({message: `Clicked <b>Join</b> button.`}));
    dispatch({type: types.SET_JOIN_IS_CLICKED});

    // roomController: connectToSession()
    dispatch(setErrorInfoBlockVisibleAction(false, {}));

    // roomController: processPartnerAvailability()
    setTimeout(async () => {
        const isVideoCompleted = await dispatch(checkIfVideoCompleted());
        const heartbeatPassed = Date.parse(sessionStartTime) + 150 * 1000 < getSyncDateNow();
        const {room: {partnerConnected}} = getState();

        if (isVideoCompleted || (heartbeatPassed && !partnerConnected)) {
            console.warn('FISBOWL SHOWED FROM ROOM CONTROLLER processPartnerAvailability');
            dispatch(hideNextPartnerBioPopupAction());
            dispatch(switchFishbowlViewAction(isVideoCompleted ? fishbowlTypes.VIDEO_PARTNER_MOVED_ON : fishbowlTypes.VIDEO_PARTNER_LEFT))
        }
    }, 15000);
};

export const hideJoinBlockAction = () => ({type: types.HIDE_JOIN_BLOCK});

export const setIsVisitorAloneAction = (flag) => {
    return {
        type: types.SET_IS_VISITOR_ALONE,
        isVisitorAlone: flag
    }
};

export const toggleFooterControlsVisibilityAction = (showControls) => {
    return {
            type: types.TOGGLE_FOOTER_CONTROLS_VISIBILITY,
            showControls
        }
};

export const endCallAction = (needSocketEmit = true) => (dispatch, getState) => {
    const {
        controller: {
            showFishbowlPopup, fishbowlView,
            currentEvent: { eventId, minPerSession, internalEndDateAndTime },
            currentSession: { roomNumber, numberUsers }
        },
        room: { partnerConnected },
        users: { currentUser: { userId: endCallUserId } }
    } = getState();
    dispatch(hideNextPartnerBioPopupAction());

    if (needSocketEmit) {
        socketModule.socket.emit('fishbowl.user_click_end_call', { eventId, roomNumber, numberUsers, endCallUserId });
    }

    const sessionDuration = minPerSession * 60 * 1000;
    const isLastSession = getSyncDateNow() +  sessionDuration > Date.parse(internalEndDateAndTime);

    if (showFishbowlPopup && !partnerConnected && fishbowlView === fishbowlTypes.VIDEO_DISCONNECTED_O_USER) {
        if (isLastSession) {
            dispatch({
                type: types.SWITCH_FISHBOWL_VIEW,
                fishbowlView: fishbowlTypes.WRAPUP
            });
        } else {
            dispatch({
                type: types.SWITCH_FISHBOWL_VIEW,
                fishbowlView: fishbowlTypes.VIDEO_DISCONNECTED_I_USER
            });
        }
    }
    dispatch(logging({message: `Clicked <b>End call</b> button.`}));

    console.warn("END CALL");
    dispatch({ type: types.CALL_ENDED });
    dispatch(partnerConnectedAction(false));
    dispatch(setDisconnectedAction(true, {
        network: false,
        leftActive: false,
        endCall: true,
        changeDevice: false
    }));

    setTimeout(async () => {
        dispatch(switchFishbowlViewAction(isLastSession ? fishbowlTypes.WRAPUP : fishbowlTypes.VIDEO_DISCONNECTED_I_USER));
    }, 900);
};

export const setEndCallClickedAction = (flag) => (dispatch, getState) => {
    if (flag) {
        const {
            controller: { currentEvent: { eventId}, currentSession: { roomNumber } },
            users: { currentUser: { userId} }
        } = getState();
        socketModule.socket.emit('bio_progress.complete_joining', { eventId, userId, roomNumber, joined: null });
    }
    dispatch({ type: types.SET_END_CALL_CLICKED, flag });
};

export const setVideoBothConnectedAction = () => ({ type: types.SET_VIDEO_BOTH_CONNECTED });
export const setVideoDidConnectedAction = (flag) => ({ type: types.SET_VIDEO_DID_CONNECTED, flag });

export const setVideoConnectedAction = (connected) => async (dispatch, getState) => {
    const {
        controller: {
            directMode, isLoading,
            currentEvent: { eventId },
            currentSession: { sessionNumber, roomNumber } },
        users: {
            currentUser: { userId: currentUserId },
            otherUser: { userId: otherUserId } },
    } = getState();

    if (directMode || isLoading || !currentUserId || !eventId) return false;

    if (!connected && otherUserId === '') {
        return false;
    }

    const userId = currentUserId;

    dispatch({ type: types.SET_IS_LOADING, isLoading: true });
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/set-video-connected`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber),
        roomNumber,
        connected
    });
    dispatch({ type: types.SET_IS_LOADING, isLoading: false });

    if (!response || response.data.status !== 200) {
        console.error(`
            Error while setting user session video connected as ${connected ? 'yes' : 'no'} for event: ${eventId}, 
            user: ${userId}, session number: ${sessionNumber}`,
            response ? response.data.message : response.data.message, ''
        );
        return false;
    }

    if (connected) {
        dispatch({ type: types.SET_VIDEO_CONNECTED });
    }

    return true;
};

export const partnerConnectedAction = (partnerConnected) => (dispatch, getState) => {
    const { showNextPartnerBioPopup } = getState().controller;
    if (partnerConnected) {
        setTimeout(() => {
            const fadeScreen = document.getElementById('fade-screen');
            if (fadeScreen) {
                fadeScreen.style.transition = 'background-color 2s ease-out';
                fadeScreen.className = ''
            }
            if (showNextPartnerBioPopup) dispatch(hideNextPartnerBioPopupAction())
        }, 2000)
    }
    dispatch({ type: types.PARTNER_CONNECTED, partnerConnected });
};

export const partnerClickedEndCallAction = () => ({ type: types.PARTNER_CLICKED_END_CALL });

export const checkIfVideoCompleted = () => async (dispatch, getState) => {
    const {
        controller: {
            currentEvent: { eventId },
            currentSession: { sessionNumber, roomNumber } },
        users: {
            otherUser: { userId } }
    } = getState();

    if (userId === '') {
        return false;
    }

    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/user-sessions/is-video-completed`, {
        eventId,
        userId,
        sessionNumber: parseFloat(sessionNumber),
        roomNumber
    }, {crossDomain: true});

    return response.data.status === 200;
};

export const memberJoinConferenceAction = () => ({ type: types.MEMBER_JOIN_CONFERENCE });
export const memberLeaveConferenceAction = () => ({ type: types.MEMBER_LEAVE_CONFERENCE });

export const myChoiceAction = (variant) => ({ type: types.SET_MY_CHOICE, variant });
const otherUserChoiceAction = (variant) => ({ type: types.SET_OTHER_USER_CHOICE, variant });
const otherUserBChoiceAction = (variant) => ({ type: types.SET_OTHER_USER_B_CHOICE, variant });

export const clearChoicesAndSelectNextSessionVariantAction = () => (dispatch) => {
    dispatch(setSelectedNextSessionVariantAction('next'));
    dispatch(clearChoicesAction());
};

export const clearChoicesAction = () => ({type: types.CLEAR_CHOICES});

export const callExtendedAction = () => ({ type: types.CALL_EXTENDED });

export const processExtendCall = () => async (dispatch) => {
  await dispatch(userExtendCallAction());
  dispatch(needShowRightFlyinAction(false));
  dispatch(callExtendedAction());
  setTimeout(() => {
    dispatch(clearExtendCallOptionsAction());
    dispatch(showExtendCallPopupAction(false));
  }, 5 * 1000)
};

export const userMadeChoiceAction = (userId, variant) => async (dispatch, getState) => {
    const {currentUser: { userId: myId }, otherUser: { userId: otherUserId, inRoom: otherUserInRoom }, otherUserB: { userId: otherUserBId }} = getState().users;
    const { callAlreadyExtended } = getState().room;
    switch (userId) {
      case myId :
          dispatch(myChoiceAction(variant));
          break;
      case otherUserId :
          dispatch(otherUserChoiceAction(variant));
          break;
      case otherUserBId :
          if (!otherUserInRoom) {
              dispatch(otherUserChoiceAction(variant));
          }else {
              dispatch(otherUserBChoiceAction(variant));
          }
          break;
      default:
          break;
    }

    const { myChoice, otherUserChoice, otherUserBChoice } = getState().room;

    if ((((otherUserId && !otherUserBId) || (otherUserBId && !otherUserInRoom)) && [myChoice, otherUserChoice].includes('pass')) ||
        (otherUserId && otherUserBId && otherUserChoice === 'pass' && otherUserBChoice === 'pass')) {
        setTimeout(() => {
            dispatch(showExtendCallPopupAction(false));
        }, 5 * 1000)
    }

    if ((myChoice === 'extend' && (otherUserChoice === 'extend' || otherUserBChoice === 'extend')) && !callAlreadyExtended) {
        dispatch(processExtendCall());
    }
};

const otherUserSeenNextSessionPopupAction = () => ({ type: types.SET_OTHER_USER_SEEN_NEXT_SESSION_POPUP });
const otherUserBSeenNextSessionPopupAction = () => ({ type: types.SET_OTHER_USER_B_SEEN_NEXT_SESSION_POPUP });

export const userSeenNextSessionPopupAction = (userId) => async (dispatch, getState) => {
    const {otherUser: { userId: otherUserId }, otherUserB: { userId: otherUserBId }} = getState().users;
    switch (userId) {
      case otherUserId :
          dispatch(otherUserSeenNextSessionPopupAction());
          break;
      case otherUserBId :
          dispatch(otherUserBSeenNextSessionPopupAction());
          break;
      default:
          break;
    }
};

export const setRoomStore = (store) => ({ type: types.SET_ROOM_STORE, store });
