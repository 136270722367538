import DetectRTC from "detectrtc";

import types from "../constans";
import config from "../../utils/config";
import { getInstanceAxios } from "../../utils/helpers";


export const storeTestDataAction = (data, errorCode, testError) => async (dispatch, getState) => {
    const {
        controller: { currentEvent: { eventId } },
        users: { currentUser: { userId } },
        test
    } = getState();

    data.uid = userId || test.userId;
    data.eventId = eventId;
    await getInstanceAxios().post(`${config.NODE_API_URL}/test/store`,
        {
            ...data,
            errorCode, testError,
        });
};

export const sendDetectRTCStatisticAction = (status, statusMessage, testResults, errorCode, testError) => async (dispatch, getState) => {
    const { users: { currentUser: { userId } }, test } = getState();
    const detectRtcData = { ...window.detectRtcData };
    [
        'DetectLocalIPAddress', 'checkWebSocketsSupport', 'load', 'version',
        'MediaDevices', 'MediaStream', 'MediaStreamTrack', 'RTCPeerConnection',
    ].map(key => delete detectRtcData[key]);

    detectRtcData.uid = userId || test.userId;
    detectRtcData.status = status;
    detectRtcData.statusMessage = statusMessage;
    detectRtcData.testResults = testResults;
    detectRtcData.errorCode = errorCode;
    detectRtcData.testError = testError;

    Object.keys(detectRtcData)
        .filter(key => typeof detectRtcData[key] === typeof true)
        .forEach(key => {
            detectRtcData[key] = Number(detectRtcData[key]);
        });
    await getInstanceAxios().post(`${config.NODE_API_URL}/test/store-detect-rtc-info`, detectRtcData);
};

export const showSupportAction = (flag) => ({ type: types.SHOW_SUPPORT, flag });
export const slideWebcamFlowAction = () => ({ type: types.SLIDE_WEBCAM_FLOW });

export const getSupportFaqsAction = (error_code) => async (dispatch) => {
    console.warn('error_code', error_code);
    const detectRtcData = window.detectRtcData || DetectRTC;
    const params = {
        error_code,
        os_name: detectRtcData ? detectRtcData.osName : 'n/d',
        os_version: detectRtcData && detectRtcData.osVersion ? detectRtcData.osVersion.toString() : 'n/d',
        browser: detectRtcData ? detectRtcData.browser : 'n/d',
    };
    console.warn('Support FAQ: ', params);
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/test/get-support-faqs`, params,{ crossDomain: true });
    const { faq = [], extra = [], header } = response.data;
    if (faq.length || extra.length) {
        dispatch(setFaqItemsAction(faq, extra, header));
        dispatch(slideWebcamFlowAction());
        setTimeout(() => {
            dispatch(showSupportAction(true));
        }, 300);

    }
};

export const storeManualBugReport = (type) => async (dispatch, getState) => {
    const { users: { currentUser: { userId } } } = getState();
    const data = {
        uid: userId,
        message: `Manual error reporting. Issue with ${type}.`,
    };
    await getInstanceAxios().post(`${config.NODE_API_URL}/test/manual-test/store`, data,{ crossDomain: true });
};

export const getDirectSupportFaqsAction = (os_name, os_version, browser, error_code) => async (dispatch) => {
    const params = {
        error_code,
        os_name,
        os_version,
        browser,
    };
    const response = await getInstanceAxios().post(`${config.NODE_API_URL}/test/get-support-faqs`, params,{ crossDomain: true });
    const { faq = [], extra = [], header = '' } = response.data;
    if (faq.length || extra.length) {
        dispatch(setFaqItemsAction(faq, extra, header));
        dispatch(showSupportAction(true));
    }
};

export const setUserIdAction = (userId) => ({ type: types.SET_USER_ID, userId });
export const setTestDirectModeAction = (mode) => ({ type: types.SET_TEST_DIRECT_MODE, mode });
export const setProceedUrlAction = (proceedUrl) => ({ type: types.SET_PROCEED_URL, proceedUrl });
export const setStageAction = (stage) => ({ type: types.SET_STAGE, stage });
export const setSuccessAction = () => ({ type: types.SET_SUCCESS });
export const setFailedAction = (flag) => ({ type: types.SET_FAILED, flag });
export const setWarningAction = () => ({ type: types.SET_WARNING });
export const setInProgressAction = (inProgress) => ({ type: types.SET_IN_PROGRESS, inProgress });
export const setProgressMessageAction = (message) => ({ type: types.SET_PROGRESS_MESSAGE, message });
export const setStepCounterAction = (counter) => ({ type: types.SET_STEP_COUNTER, counter });
export const setStepProgressAction = (id, progress) => ({ type: types.SET_STEP_PROGRESS, id, progress });
export const setStepStateAction = (id, state) => ({ type: types.SET_STEP_STATE, id, state });
export const showEquipmentModalAction = (flag) => ({ type: types.SHOW_EQUIPMENT_MODAL, flag });
export const setFaqItemsAction = (faq, extra, header) => ({ type: types.SET_FAQ_ITEMS, faq, extra, header });

export const setTestMicOnlyModeAction = (micOnly) => ({ type: types.SET_TEST_MIC_ONLY_MODE, micOnly });
export const setTestParentUrlAction = (data) => ({ type: types.SET_TEST_PARENT_URL, data });
export const setTestWithoutWebcamAction = () => (dispatch) => {
    dispatch(setInProgressAction(true));
    dispatch(setFailedAction(false));
    dispatch(showWithoutWebcamButtonAction(false));
    dispatch(showSupportAction(false));
    dispatch({ type: types.SET_TEST_WITHOUT_WEBCAM })
};
export const showWithoutWebcamButtonAction = (flag) => ({ type: types.SHOW_WITHOUT_WEBCAM_BUTTON, flag });

export const setMicSelectedAction = (deviceId) => ({ type: types.SET_MIC_SELECTED, deviceId });
export const setWebcamSelectedAction = (deviceId) => ({ type: types.SET_WEBCAM_SELECTED, deviceId });
export const setTestPermitMobileAction = (permitMobile) => ({ type: types.SET_TEST_PERMIT_MOBILE, permitMobile });
