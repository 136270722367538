import types from "../constans";

const initialState = {
    oktaAuthInstance: null,
    isLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOADING:
            return {...state, isLoading: action.isLoading};

        case types.SET_OKTA_AUTH_INSTANCE:
            return {...state, oktaAuthInstance: action.oktaAuth};

        default:
            return state
    }
}
