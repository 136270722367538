import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import oneMinuteSound from "../../assets/sounds/2_one_minute_remaining.wav";
import socketModule from "../../utils/socketModule";
import { getSyncDateNow } from "../../utils/helpers";
import fishbowlTypes from "../Popups/FishbowlViewTypes";
import NextPartnerBio from "../Popups/NextPartnerBio";
import ExtendCallPopup from "../Popups/ExtendCall";
import NewMeet from "../Popups/NewMeet";
import Add3rdUserToCall from "../Popups/Add3rdUserToCall";
import Support from "../TestContainerElements/Support";
import OfferToJoin from "../OfferToJoin";
import FadeScreen from "../ContainerElements/FadeScreen";
import LiveMessage from "../Popups/LiveMessage";
import ZoomVideo from "../ZoomVideo";
import {
    clearAcceptedInviteAction,
    getCurrentUserSession,
    getHeartBeatInfo,
    hideNextPartnerBioPopupAction,
    logging,
    pushUserSessionToStore,
    refreshCurrentUserSessionAction,
    setExtendCallBlockedAction,
    setExtendCallByMinutesAction,
    setExtendCallDirectModeAction,
    setPartyViewAction,
    setSurveySuccessfullyPostedAction,
    setUserSessionPartyViewAction,
    show3rdPartyPopupAction,
    showExtendCallPopupAction,
    showNextPartnerBioPopupAction,
    showSurveyPopupAction,
    submitSurveyFormAction,
    switchFishbowlViewAction,
    userDeclineInviteAction
} from "../../store/controller/actions";
import {
    setInvitedUserAction,
    setMicOnlyModeAction,
    setOtherUserAction,
    setOtherUserBAction,
    setPartnerCompleteJoiningAction,
    setPartnerCompleteMatchesAction,
    setPartnerCompletePriorCallAction,
    setPartnerCompleteSurveyAction
} from "../../store/users/actions";
import {
    clearChoicesAndSelectNextSessionVariantAction,
    myChoiceAction,
    processExtendCall,
    setVideoBothConnectedAction,
    setVideoDidConnectedAction,
    userMadeChoiceAction
} from "../../store/room/actions";
import store from "../../store";


const VideoSupportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    color: #fff;
    width: 100%;
    z-index: 1001;
    padding-bottom: 65%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
`;

const _oneMinuteAudio = new Audio(oneMinuteSound);

const VideoContainer = ({
                            screenView, containerLoaded, directMode, location,
                            refreshCurrentUserSessionAction, setPartyViewAction,
                            setMicOnlyModeAction, currentEvent, currentSession,
                            currentUserId, setExtendCallDirectModeAction,
                            setExtendCallByMinutesAction, myChoiceAction,
                            showExtendCallPopupAction, processExtendCall,
                            clearChoicesAndSelectNextSessionVariantAction,
                            userMadeChoiceAction,
                            setExtendCallBlockedAction, setInvitedUserAction,
                            setUserSessionPartyViewAction, show3rdPartyPopupAction,
                            logging, clearAcceptedInviteAction, userDeclineInviteAction,
                            getCurrentUserSession, pushUserSessionToStore,
                            otherUser, otherUserB, setOtherUserAction, setOtherUserBAction,
                            submitSurveyFormAction, setVideoBothConnectedAction,
                            setVideoDidConnectedAction, setPartnerCompletePriorCallAction,
                            setPartnerCompleteSurveyAction, setPartnerCompleteJoiningAction,
                            setPartnerCompleteMatchesAction, roomNumber, partnerConnected,
                            showNextPartnerBioPopupAction, showSurveyPopupAction,
                            setSurveySuccessfullyPostedAction, getHeartBeatInfo,
                            hideNextPartnerBioPopupAction,
                            fishbowlView, switchFishbowlViewAction,
                            accessGrantedErrorBlock, showSupport,
                            showFoundRightFlyIn, show3rdPartyPopup, partyView,
                            showSurveyPopup, showNextPartnerBioPopup, showExtendCallPopup
                        }) => {
    const heartbeat75Timeout = useRef(null);
    const prevScreenView = useRef(screenView);
    const prevContainerLoaded = useRef(containerLoaded);

    useEffect(() => {
        (async () => {
            document.body.style.backgroundColor = "#000";
            if (!directMode) await refreshCurrentUserSessionAction();
            if (!location || location.pathname.search("/room/") === -1) {
                //disable browser back button
                window.history.pushState(null, null, window.location.href);
                window.onpopstate = function() {
                    window.history.go(1);
                };
            }
            setPartyViewAction(null);

            if (window.location.pathname.includes("/room/")) {
                setMicOnlyModeAction(true);
            }

            socketModule.socket.emit("connect_to_self_room", {
                eventId: currentEvent.eventId,
                userId: currentUserId
            });

            socketModule.socket.on("extend_call_choice", async ({ userId, variant, options }) => {
                if (options.initial && options.directMode) {
                    if (userId !== currentUserId) return;
                    setExtendCallDirectModeAction();
                    setExtendCallByMinutesAction(options.extendCallByMinutes);
                    myChoiceAction("extend");
                    showExtendCallPopupAction(true);
                    await processExtendCall();
                    if (options.initial) setTimeout(() => {
                        clearChoicesAndSelectNextSessionVariantAction();
                    }, 10 * 1000);
                    return;
                }
                if (options.extendCallByMinutes) {
                    setExtendCallByMinutesAction(options.extendCallByMinutes);
                }
                if (options.directMode && options.userId === currentUserId) {
                    setExtendCallByMinutesAction(options.extendCallByMinutes);
                }
                if (options.initial) setTimeout(() => {
                    clearChoicesAndSelectNextSessionVariantAction();
                }, 40 * 1000);

                userMadeChoiceAction(userId, variant);

                const { room: {callAlreadyExtended}} = store.getState();
                if (!callAlreadyExtended && variant === "extend") showExtendCallPopupAction(true);
            });

            socketModule.socket.on("extend_call.block", async () => {
                setExtendCallBlockedAction(true);
                showExtendCallPopupAction(false);
            });

            socketModule.socket.on("3rd-party.ask_to_invite", async ({ invitedUser }) => {
                const { controller: {currentSession: {sessionEndTime}}} = store.getState();
                const now = new Date(getSyncDateNow());
                const future = now.setSeconds(now.getSeconds() + 240);
                if (Date.parse(sessionEndTime) > future) {
                    setInvitedUserAction(invitedUser);
                    setPartyViewAction("ask");
                    await setUserSessionPartyViewAction("ask");
                    show3rdPartyPopupAction(true);
                    logging({ message: `See 3rd party popup with party view <b>ask</b> | <small> VideoContainer(96) </small>` });
                }
            });

            socketModule.socket.on("3rd-party.invite_accepted", ({ userId }) => {
                const {
                    users: {invitedUser},
                    controller: {
                        currentEvent: {eventId, minPerSession},
                        currentSession: {roomNumber},
                    }
                } = store.getState();

                if (currentUserId === userId) {
                    socketModule.socket.emit("3rd-party.all_accepted", {
                        eventId,
                        minPerSession: minPerSession,
                        roomNumber: roomNumber,
                        invitedUserId: invitedUser.userId,
                        fromHolding: invitedUser.fromHolding
                    });
                }
            });

            socketModule.socket.on("3rd-party.create_session_state", ({ isCreated }) => {
                if (isCreated) {
                    setPartyViewAction("inviting");
                } else {
                    setPartyViewAction("found");

                    setTimeout(() => {
                        show3rdPartyPopupAction(false);
                        setPartyViewAction(null);
                        setInvitedUserAction(null);
                        clearAcceptedInviteAction();
                    }, 4000);
                }
            });

            socketModule.socket.on("3rd-party.invite_declined", ({ userId }) => {
                userDeclineInviteAction(userId);
                setPartyViewAction(currentUserId === userId ? "iDeclined" : "oDeclined");
            });

            socketModule.socket.on("3rd-party.fishbowl-user_cancel_search", () => {
                setPartyViewAction("oPartnerFound");
            });

            socketModule.socket.on("user-session_updated", async () => {
                const userSession = await getCurrentUserSession();
                if (userSession) {
                    await pushUserSessionToStore(userSession);
                }
            });

            socketModule.socket.on("play-notification", async ({ type }) => {
                const {
                    controller: {
                        screenView,
                        currentSession: { sessionEndTime },
                    }
                } = store.getState();

                switch (type) {
                    case "one-minute" :
                        if (screenView !== "video") return;
                        if (Date.parse(sessionEndTime) - getSyncDateNow() > 60 * 1000) return;
                        _oneMinuteAudio.volume = 0.3;
                        await _oneMinuteAudio.play();
                        break;
                    default:
                        return;
                }
            });

            socketModule.socket.on("fishbowl.invited_user_moved_to_room", () => show3rdPartyPopupAction(false));

            socketModule.socket.on("3way_call.user_moved_to_next_room", ({ userId }) => {
                const {
                    users: { otherUser, otherUserB }
                } = store.getState();

                if (userId === currentUserId) return;
                if (userId === otherUser.userId) setOtherUserAction({ ...otherUser, inRoom: false });
                if (userId === otherUserB.userId) setOtherUserBAction({ ...otherUserB, inRoom: false });
            });

            socketModule.socket.on("usersession.both_user_connected", async () => {
                const {
                    controller: {
                        currentEvent: {eventId},
                        currentSession: {
                            bothConnected, sessionNumber, roomNumber
                        }
                    }
                } = store.getState();

                if (!bothConnected) {
                    logging({
                        message: `<b>Successfully connected with a partner in room ${roomNumber} (both_connected)</b> | <small> VideoContainer(151) </small>`,
                        status: "success"
                    });
                }
                await submitSurveyFormAction({
                    event: eventId,
                    user: currentUserId,
                    session_number: sessionNumber
                }, "both_connected");
                setVideoBothConnectedAction();
            });

            socketModule.socket.on("usersession.did_user_connected", async (flag) => {
                setVideoDidConnectedAction(flag);
            });

            socketModule.socket.on("bio_progress.complete_prior_call", async ({ userId }) => {
                setPartnerCompletePriorCallAction(userId);
            });
            socketModule.socket.on("bio_progress.complete_survey", async ({ userId }) => {
                setPartnerCompleteSurveyAction(userId);
            });
            socketModule.socket.on("bio_progress.complete_joining", async ({ userId, joined }) => {
                setPartnerCompleteJoiningAction(userId, joined);
            });
            socketModule.socket.on("bio_progress.complete_matches", async ({ userId }) => {
                setPartnerCompleteMatchesAction(userId);
            });
        })();

        initHeartBeat75();

        return () => {
            if (heartbeat75Timeout && heartbeat75Timeout.current) {
                clearTimeout(heartbeat75Timeout.current);
            }

            socketModule.socket.off("3rd-party.invite_accepted");
            socketModule.socket.off("3rd-party.create_session_state");
            socketModule.socket.off("3way_call.user_moved_to_next_room");
        };
    }, []);

    useEffect(() => {
        if (heartbeat75Timeout && heartbeat75Timeout.current) {
            clearTimeout(heartbeat75Timeout.current);
            initHeartBeat75();
        }
    }, [roomNumber]);

    useEffect(() => {
        if (!prevContainerLoaded.current && containerLoaded) {
            const hasClickedEndCall = window.sessionStorage.getItem(`clicked-end-call`) === currentSession.roomNumber;
            const hasPartnerClickedEndCall = window.sessionStorage.getItem(`partner-clicked-end-call`) === currentSession.roomNumber;
            const nextUsers = [otherUser, otherUserB].filter(user => user.userId);

            if (!hasClickedEndCall && !hasPartnerClickedEndCall && !partnerConnected && screenView === "video" && nextUsers.length) {
                showNextPartnerBioPopupAction(true);
            }
            if (screenView === "survey") showSurveyPopupAction(true);

            setSurveySuccessfullyPostedAction(true);
            prevContainerLoaded.current = containerLoaded;
        }
    }, [containerLoaded, currentSession, otherUser, otherUserB, partnerConnected, screenView]);

    useEffect(() => {
        let timeoutId;

        const handleScreenChange = () => {
            if (prevScreenView.current === "video" && screenView === "survey") {
                timeoutId = setTimeout(() => {
                    showSurveyPopupAction(true);
                }, 500);
            }
            if (prevScreenView.current === "survey" && screenView === "video") {
                initHeartBeat75();
            }
            prevScreenView.current = screenView;
        };

        handleScreenChange();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [screenView]);

    const initHeartBeat75 = () => {
        heartbeat75Timeout.current = setTimeout(async () => {
            setTimeout(async () => {
                if (directMode) return;
                const heartbeat = await getHeartBeatInfo();
                const {
                    room: { joinIsClicked },
                    users: { otherUserB },
                    controller: { showFishbowlPopup }
                } = store.getState();

                console.warn("heartbeat from video container 75");
                console.warn({
                    RESULT: !!((joinIsClicked && heartbeat.videoConnected !== true) && otherUserB.userId === ""),
                    joinIsClicked: joinIsClicked,
                    heartbeat: heartbeat.videoConnected,
                    otherUserBUserId: otherUserB.userId,
                    showFishbowlPopup: showFishbowlPopup
                });

                if ((joinIsClicked && heartbeat.videoConnected !== true) && otherUserB.userId === "") {
                    hideNextPartnerBioPopupAction();
                    if (!showFishbowlPopup) {
                        console.warn("FISBOWL SHOWED FROM VIDEO CONTAINER initHeartBeat75");
                        switchFishbowlView(heartbeat);
                    }
                }
            }, 200);
        }, getTimeoutDelay(75));
    };

    const switchFishbowlView = (heartbeat) => {
        const lastSession = checkLastSession();
        switch (true) {
            case !partnerConnected && fishbowlView !== "break" && screenView !== "break":
                setTimeout(() => {
                    switchFishbowlViewAction(fishbowlTypes.VIDEO_NO_SHOW);
                }, 2000);
                break;
            case heartbeat.availableToMet === false :
                switchFishbowlViewAction(fishbowlTypes.MET_EVERYONE);
                break;
            case lastSession :
                switchFishbowlViewAction(fishbowlTypes.WRAPUP);
                break;
            case heartbeat.videoCompleted === true :
                switchFishbowlViewAction(fishbowlTypes.VIDEO_PARTNER_MOVED_ON);
                break;
            case heartbeat.videoCompleted === false :
                switchFishbowlViewAction(fishbowlTypes.VIDEO_PARTNER_LEFT);
                break;
            default:
                break;
        }
    };

    const checkLastSession = () => {
        const { minPerSession, internalEndDateAndTime } = currentEvent;

        const sessionDuration = minPerSession * 60 * 1000;
        return getSyncDateNow() + sessionDuration > Date.parse(internalEndDateAndTime);
    };

    const getTimeoutDelay = (seconds) => {
        const { sessionStartTime } = currentSession;
        let delay = sessionStartTime ? (Date.parse(sessionStartTime) + seconds * 1000) - getSyncDateNow() : seconds * 1000;
        if (delay < 45000) delay = 45000;
        if (delay > 75000) delay = 75000;
        return delay;
    };

    return (
        <>
            {accessGrantedErrorBlock && showSupport &&
                <VideoSupportWrapper>
                    <Support/>
                </VideoSupportWrapper>
            }

            <LiveMessage/>
            <OfferToJoin/>
            <ZoomVideo location={location}/>

            {showExtendCallPopup && screenView === "video" && partnerConnected &&
                <ExtendCallPopup/>
            }
            {showFoundRightFlyIn &&
                <NewMeet moveToRoom={true}/>
            }
            {(show3rdPartyPopup && partnerConnected) &&
                <Add3rdUserToCall variant={partyView}/>
            }
            {showNextPartnerBioPopup && !showSurveyPopup &&
                <NextPartnerBio/>
            }
            <FadeScreen/>
        </>
    );
};

export default connect(
    store => ({
        currentUserId: store.users.currentUser.userId,
        otherUser: store.users.otherUser,
        otherUserB: store.users.otherUserB,
        containerLoaded: store.controller.containerLoaded,
        isReady: store.controller.isReady,
        currentEvent: store.controller.currentEvent,
        currentSession: store.controller.currentSession,
        acceptedInvite: store.controller.acceptedInvite,
        show3rdPartyPopup: store.controller.show3rdPartyPopup,
        showFoundRightFlyIn: store.controller.showFoundRightFlyIn,
        fishbowlView: store.controller.fishbowlView,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        showSurveyPopup: store.controller.showSurveyPopup,
        screenView: store.controller.screenView,
        partyView: store.controller.partyView,
        directMode: store.controller.directMode,
        accessGrantedErrorBlock: store.controller.accessGrantedErrorBlock,
        showExtendCallPopup: store.controller.showExtendCallPopup,
        partnerConnected: store.room.partnerConnected,
        myChoice: store.room.myChoice,
        showSupport: store.test.showSupport,
        differenceBetweenServerAndClientTime: store.controller.differenceBetweenServerAndClientTime,
        roomNumber: store.controller.currentSession ? store.controller.currentSession.roomNumber : null
    }),
    {
        userDeclineInviteAction,
        setPartyViewAction,
        show3rdPartyPopupAction,
        showExtendCallPopupAction,
        processExtendCall,
        setInvitedUserAction,
        getCurrentUserSession,
        pushUserSessionToStore,
        setUserSessionPartyViewAction,
        getHeartBeatInfo,
        switchFishbowlViewAction,
        showSurveyPopupAction,
        showNextPartnerBioPopupAction,
        hideNextPartnerBioPopupAction,
        setSurveySuccessfullyPostedAction,
        setVideoBothConnectedAction,
        setVideoDidConnectedAction,
        refreshCurrentUserSessionAction,
        userMadeChoiceAction,
        myChoiceAction,
        setExtendCallBlockedAction,
        setExtendCallDirectModeAction,
        setExtendCallByMinutesAction,
        clearChoicesAndSelectNextSessionVariantAction,
        submitSurveyFormAction,
        setPartnerCompletePriorCallAction,
        setPartnerCompleteSurveyAction,
        setPartnerCompleteJoiningAction,
        setPartnerCompleteMatchesAction,
        logging,
        setOtherUserAction,
        setOtherUserBAction,
        clearAcceptedInviteAction,
        setMicOnlyModeAction
    }
)(VideoContainer);
