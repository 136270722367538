import types from "../constans";
import { getInstanceAxios } from "../../utils/helpers";
import config from "../../utils/config";

const setSystemSettings = (settings) => ({ type: types.SET_SYSTEM_SETTINGS, settings });

export const getSystemSettingsAction = () => async (dispatch) => {
    const response = await getInstanceAxios(true).get(`${config.NODE_API_URL}/system/settings`);

    if (response && response.data.status === 200) {
        const { virtual_background } = response.data.settings || {};
        const settings = {
            virtualBackground: !!virtual_background
        };
        dispatch(setSystemSettings(settings));
    }
};
