import types from "../constans";
import { getInstanceAxios, getSyncDateNow } from "../../utils/helpers";
import config from "../../utils/config";

export const setFishbowlButtonSelected = ({button, partyView}) => async (dispatch, getState) => {
    if (partyView === 'found') {
        dispatch({type: types.SET_FISHBOWL_BUTTON_SELECTED, button, partyView});
    } else {
        const {controller: { currentSession: { sessionEndTime } } } = getState();
        if ((getSyncDateNow() + 15000) > Date.parse(sessionEndTime)) {
            dispatch({type: types.SET_FISHBOWL_BUTTON_SELECTED, button, partyView: 'timedOut'});
            return;
        }
        dispatch({type: types.SET_FISHBOWL_BUTTON_SELECTED, button, partyView});

        if (partyView === 'timedOut') {
            dispatch({ type: types.SET_FALSE_FIRST_TIME_SEARCH_STATE });
            return;
        }

        const {
            controller: { checkDesignMode, currentEvent: { eventId }, currentSession: { sessionNumber } },
            users: { currentUser: { userId }, otherUser: { userId: otherUserId } },
            fishbowl: { firstTimeSearch },
        } = getState();

        if (checkDesignMode) {
            return;
        }

        const response = await getInstanceAxios().post(`${config.NODE_API_URL}/fishbowl/create-from-videosystem`, {
            dropFishbowl: button === 2,
            eventId,
            sessionNumber,
            userId,
            otherUserId,
            continueSearch: !firstTimeSearch
        }, {crossDomain: true});

        const {possibleRooms} = response.data;
        if (possibleRooms !== undefined && possibleRooms === 0 && firstTimeSearch) {
            dispatch({ type: types.SET_FALSE_FIRST_TIME_SEARCH_STATE });
            if (getState().fishbowl.fishbowlPartyView !== 'found') {
                dispatch({type: types.SET_FISHBOWL_BUTTON_SELECTED, button: 1, partyView: 'timedOut'})
            }
        }
    }
};

export const switchFishbowlPartyView = (value) => ({ type: types.SWITCH_FISHBOWL_PARTY_VIEW, value });

export const clearFishbowlOptionsAction = () => ({ type: types.CLEAR_FISHBOWL_OPTIONS });

export const setFishbowlStore = (store) => ({ type: types.SET_FISHBOWL_STORE, store });
